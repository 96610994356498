import React, { useState } from "react";

// import AddCompanyNameModal from "./add_company_name_modal";
// import SaveCompanyDetailsModal from "./save_company_details_modal";
import AddBusinessUnitModal from "./add_business_unit_modal";
import EditBusinessUnitModal from "../edit-company/edit_company_details";

const AddCompanyModal = ({ show, cred_debt_type, cred_debt_city, cred_debt_gst, cred_debt_address, cred_debt_state, cred_debt_name, companyId, setShowAddCompanyModal, setCounterParty, setDisplayAlert }) => {
  const [companyName, setCompanyName] = useState("");
  const [companyBranches, setCompanyBranches] = useState([]);
  const [selectedBranch] = useState();
  const [step, setStep] = useState(1);

  const clear = () => {
    setCompanyName("");
    setCompanyBranches([]);
    setStep(1);
  };

  const renderContent = () => {
    if (step === 1) {
      return (
        <AddBusinessUnitModal
          companyBranches={companyBranches}
          setCompanyBranches={setCompanyBranches}
          setShowAddCompanyModal={setShowAddCompanyModal}
          setStep={setStep}
          gst={companyName}
          cred_debt_gst={cred_debt_gst}
          cred_debt_state={cred_debt_state}
          cred_debt_city={cred_debt_city}
          cred_debt_address={cred_debt_address}
          cred_debt_name={cred_debt_name}
          cred_debt_type={cred_debt_type}
          companyId={companyId}
          setDisplayAlert={setDisplayAlert}
          setCounterParty={setCounterParty}
          clear={clear}
        />
        // <AddCompanyNameModal
        //   companyName={companyName}
        //   setCompanyName={setCompanyName}
        //   setShowAddCompanyModal={setShowAddCompanyModal}
        //   setStep={setStep}
        // />
      );
    } else if (step === 2) {
      console.log();
      return (
        <AddBusinessUnitModal
          companyBranches={companyBranches}
          setCompanyBranches={setCompanyBranches}
          setShowAddCompanyModal={setShowAddCompanyModal}
          setStep={setStep}
          gst={companyName}
          companyId={companyId}
          setDisplayAlert={setDisplayAlert}
          clear={clear}
          setCounterParty={setCounterParty}

        />
        // <SaveCompanyDetailsModal
        //   companyName={companyName}
        //   companyBranches={companyBranches}
        //   branch={selectedBranch}
        //   setSelectedBranch={setSelectedBranch}
        //   setStep={setStep}
        //   setShowAddCompanyModal={setShowAddCompanyModal}
        //   setDisplayAlert={setDisplayAlert}
        //   clear={clear}
        // />
      );
    } else if (step === 3) {
      return (
        <EditBusinessUnitModal
          branch={selectedBranch}
          branches={companyBranches}
          setCompanyBranches={setCompanyBranches}
          isNewUnit={true}
          companyId={companyId}
          setShowEditCompanyModal={setShowAddCompanyModal}
          setStep={setStep}
          setCounterParty={setCounterParty}
        />
        // <AddBusinessUnitModal
        //   companyBranches={companyBranches}
        //   setCompanyBranches={setCompanyBranches}
        //   setShowAddCompanyModal={setShowAddCompanyModal}
        //   setStep={setStep}
        // />
      );
    }
    // else if (step === 4) {
    //   return (
    //   );
    // }
  };

  return <React.Fragment>{show ? renderContent() : ""}</React.Fragment>;
};

export default AddCompanyModal;
