import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

// import Company from "./company";
import Alert from "../../common/alert/alert";
import Breadcrumb from "../../common/breadcrums";
import AddCompanyModal from "./add-company/add_company";
import EditCompanyModal from "./edit-company/edit_company";

import Pagination from "../../common/pagination";
import { NavLink } from "react-router-dom";
import "./companies.css";

const Companies = () => {
  const [companies, setCompanies] = useState({
    companies: [],
    totalPage: 1,
    loader: true,
  });
  const [page, setPage] = useState(1);
  const [showAddCompanyModal, setShowAddCompanyModal] = useState(false);
  const [showEditCompanyModal, setShowEditCompanyModal] = useState(false);
  const [displayAlert, setDisplayAlert] = useState({
    display: false,
    status: false,
    message: "",
  });
  const [displayAlert2, setDisplayAlert2] = useState({
    display: false,
    status: false,
    message: "",
  });

  const companyId = useRef({});

  const handleEditModalClick = (id) => {
    companyId.current = id;
    setShowEditCompanyModal(true);
  };

  const getCompanies = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/company/paged/${page}?org_id=${localStorage.getItem('zc_org_id')}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const { data } = response;
      setCompanies({
        companies: data.data,
        totalPage: data.totalPage,
        loader: false,
      });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getCompanies();
  },
    [page]);


  const pageChange = (e) => {
    let p = e.target.attributes.getNamedItem("data-page").value;
    setCompanies({ companies: [], loader: true });
    setPage(parseInt(p));
  };
  const getSearchClients = (opt) => {
    console.log(opt);

    if (opt !== undefined && opt !== null && opt.length >= 3) {
      setCompanies({ companies: [], loader: true });
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/company/all?name=${opt}`, null, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          const { data } = response;

          // let data = response.data.data;
          console.log("all clients ", data);
          // setCompanies(data);

          setCompanies({
            companies: data.data,
            totalPage: data.totalPage,
            loader: false,
          });
        })

        .catch((e) => {
          console.log(e);
          // console.log(data);
        })
        .finally((f) => { });
    } else {
      // getCompanies();
      console.log("loading");
    }
  };
  const deleteCompany = async (e) => {
    console.log(e.target.parentNode.getAttribute("data-id"));
    let Id = e.target.parentNode.getAttribute("data-id");
    const confirmed = window.confirm('Are you sure you want to delete this company?');
    if (confirmed) {
      try {
        const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/company/${Id}`);
        console.log(response);
        if (response.data.status === "Success") {
          alert("Company deleted successfully");
          getCompanies();
        } else {
          console.log("Failed to delete company:", response.data);
          alert("Failed to delete company");
        }
      } catch (error) {
        console.log("Failed to delete company:", error);
        alert("Failed to delete company");
      }
    }
  }
  const handleSearchSubmit = (event) => {
    event.preventDefault();
    console.log(event);
    const searchTerm = event.target.value;
    getSearchClients(searchTerm);
  };
  return (
    <div style={{ margin: "3vh" }}>
      <Breadcrumb paths={[{ label: "All Client", active: true }]} ></Breadcrumb>
      {displayAlert.display ? (
        <Alert status={displayAlert.status} setDisplayAlert={setDisplayAlert}>
          {displayAlert.message}
        </Alert>
      ) : null}
      {displayAlert2.display ? (
        <Alert status={displayAlert2.status} setDisplayAlert={setDisplayAlert2}>
          {displayAlert2.message}
        </Alert>
      ) : null}
      <div className="mb-3 d-flex justify-content-between align-items-center mt-2" style={{ width: "100%" }}>
        <div className="zc-page-title">
          All Clients
        </div>
        <button
          className="btn zeconcile-btn"
          type="button"
          onClick={() => setShowAddCompanyModal(true)}
        >
          <span>Add New Billing Company</span>
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Icon/ 18px/ Arrow Right">
              <path id="Vector" d="M7.19629 14.3218L12.1963 9.32178L7.19629 4.32178" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
          </svg>

        </button>
      </div>
      <div className="zc-search-invoices">
        <ul className="navbar-nav align-items-center d-none d-lg-block">
          <li className="nav-item">
            <div className="search-box">
              <form className="position-relative" data-bs-toggle="search" data-bs-display="static">
                <input onChange={handleSearchSubmit} className="form-control search-input fuzzy-search" type="search" placeholder="Search..." aria-label="Search" />
                <svg className="svg-inline--fa fa-search fa-w-16 search-box-icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                  <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg>
              </form>

              <div className="text-center mt-n3">
                <p className="fallback fw-bold fs-1 d-none">No Result Found.</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
      {showAddCompanyModal ? (
        <AddCompanyModal
          show={showAddCompanyModal}
          setShowAddCompanyModal={setShowAddCompanyModal}
          setDisplayAlert={setDisplayAlert}
        />
      ) : null}

      

      <table className="zeconcile-table">
        <thead className="w-100">
          <th>Company Name</th>

          <th>GST / PAN No.</th>

          <th>State</th>

          <th>Invoice Source </th>

          <th>Creation Source</th>

          <th>Action</th>
        </thead>
        {/* </div> */}
        {companies?.companies?.map((company, index) => {
          return (
            <tr>
              <td>
                <NavLink
                  key={index}
                  className="nav-link"
                  to={`/company/${company._id}`}
                  role="button"
                  data-bs-toggle=""
                  aria-expanded="false"
                >
                  {company.name}
                </NavLink>
              </td>
              <td>
                <p style={{ textAlign: "center", marginBottom: "0px" }} >
                  {company.branches[0]?.gst}
                </p>
              </td>
              <td>{company.branches[0]?.state} </td>
              <td className="with-chip1">
                {company.branches[0]?.mobile ?
                  <span className="zeconcile-chip success">
                    <span className="dot"></span>
                    Available
                  </span>
                  : <span className="zeconcile-chip danger">
                    <span className="dot"></span>
                    To Be Added
                  </span>
                }
              </td>
              <td>{company.created_source ? company.created_source : "Manual1"}</td>
              <td>
                <span data-id={company._id} onClick={deleteCompany} className="me-1 border-0 bg-transparent">
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.19629 5.32178V22.3218H20.1963V5.32178H5.19629Z" stroke="#EF4444" strokeWidth="2" strokeLinejoin="round" />
                    <path d="M10.6963 10.3218V16.8218" stroke="#EF4444" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M14.6963 10.3218V16.8218" stroke="#EF4444" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M2.69629 5.32178H22.6963" stroke="#EF4444" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.69629 5.32178L10.3408 2.32178H15.0848L16.6963 5.32178H8.69629Z" stroke="#EF4444" strokeWidth="2" strokeLinejoin="round" />
                  </svg>
                </span>
                <button className="border-0 bg-transparent" onClick={() => handleEditModalClick(company._id)}>
                  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.125 9.34174V12.0718H3.86895L11.625 4.31231L8.88566 1.57178L1.125 9.34174Z" stroke="#475569" strokeWidth="1.5" strokeLinejoin="round" />
                  </svg>

                </button>
              </td>

            </tr>
          );
        })}

        <tr
          className={
            companies.companies.length < 1 && companies.loader === false
              ? "ps-1 pb-2"
              : "d-none"
          }
        >
          <td colSpan="6"> No Companies Found</td>
        </tr>
        <tr
          className={
            companies.loader === true
              ? " ps-1 pb-2 justify-content-center mt-3"
              : "d-none"
          }
        // style={{ height: "85px" }}
        >
          <td colSpan={6}>

            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </td>
        </tr>
        <tr className={companies.totalPage > 1 ? "" : "d-none"}>
          <td colSpan={6}>
            <Pagination
              page={page}
              pageChange={pageChange}
              totalPage={companies.totalPage}
            />
          </td>
        </tr>
      </table>
      {showEditCompanyModal ? (
        <EditCompanyModal
          show={showEditCompanyModal}
          companyId={companyId.current}
          setShowEditCompanyModal={setShowEditCompanyModal}
          setDisplayAlert2={setDisplayAlert2}
        />
      ) : null}
    </div>
  );
};

export default Companies;
