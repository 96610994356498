const Pagination = ({ page, pageChange, totalPage }) => {
  let menuItems = [];
  let pre = Number(page) - 1;
  let next = Number(page) + 1;
  // console.log(page+ "_____");

  if (totalPage <= 10) {
    menuItems = [];
    menuItems.push(
      <li className={`page-item ${pre <= 0 ? "disabled" : ""}`} key="pre">
        <span
          className="zeconcile-btn border-0 transparent "
          data-page={pre}
          onClick={pre <= 0 ? null : pageChange}
        >
          <svg data-page={pre} width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.8735 4L6.87354 9L11.8735 14" stroke="#475569" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </span>
      </li>
    );
    for (var i = 0; i < totalPage; i++) {
      console.log(i);
      menuItems.push(
        <li
          key={i + 11}
          className={`page-item ${page === i + 1 ? "active" : ""}`}
        >
          <span
            className="page-link"
            onClick={pageChange}
            data-page={i + 1}
          >
            {i + 1}
          </span>
        </li>
      );
    }
  } else {
    menuItems = [];
    menuItems.push(
      <li className={`page-item ${pre <= 0 ? "disabled" : ""}`} key="pre">
        <span
          className="zeconcile-btn border-0 transparent"
          data-page={pre}
          onClick={pre <= 0 ? null : pageChange}
        >
          <svg data-page={pre} width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.8735 4L6.87354 9L11.8735 14" stroke="#475569" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </span>
      </li>
    );
    if (page > 4 && page < totalPage - 2) {
      menuItems.push(
        <li
          key={"abd123"}
          className={`page-item ${page === 1 ? "active" : ""}`}
        >
          <span className="page-link" onClick={pageChange} data-page={1}>
            1
          </span>
        </li>,
        <li
          key={"abd124l"}
          className={`page-item ${page === 1 ? "active" : ""}`}
        >
          <span
            className="page-link"
            // onClick={pageChange}
            data-page="..."
          >
            ...
          </span>
        </li>
      );

      menuItems.push(
        <li key={"abdl125"} className={`page-item`}>
          <span
            className="page-link"
            onClick={pageChange}
            data-page={page - 2}
          >
            {page - 2}
          </span>
        </li>
      );

      menuItems.push(
        <li key={"abd125"} className={`page-item`}>
          <span
            className="page-link"
            onClick={pageChange}
            data-page={page - 1}
          >
            {page - 1}
          </span>
        </li>,
        <li key={"abd126"} className={`page-item active`}>
          <span
            className="page-link"
            onClick={pageChange}
            data-page={page}
          >
            {page}
          </span>
        </li>,
        <li key={"abd127"} className={`page-item`}>
          <span
            className="page-link"
            onClick={pageChange}
            data-page={page + 1}
          >
            {page + 1}
          </span>
        </li>,
        <li key={"abd1281"} className={`page-item`}>
          <span
            className="page-link"
            onClick={pageChange}
            data-page={page + 2}
          >
            {page + 2}
          </span>
        </li>,
        <li
          key={"abd124"}
          className={`page-item ${page === 1 ? "active" : ""}`}
        >
          <span
            className="page-link"
            // onClick={pageChange}
            data-page="..."
          >
            ...
          </span>
        </li>,
        <li
          key={"abd128"}
          className={`page-item ${page === totalPage ? "active" : ""
            }`}
        >
          <span
            className="page-link"
            onClick={pageChange}
            data-page={totalPage}
          >
            {totalPage}
          </span>
        </li>
      );
    } else {
      for (var j = 0; j < 5; j++) {
        menuItems.push(
          <li
            key={j + 11}
            className={`page-item ${page === j + 1 ? "active" : ""
              }`}
          >
            <span
              className="page-link"
              onClick={pageChange}
              data-page={j + 1}
            >
              {j + 1}
            </span>
          </li>
        );
      }

      menuItems.push(
        <li key={"abd129"} className={`page-item`}>
          <span
            className="page-link"
            // onClick={pageChange}
            data-page="..."
          >
            ...
          </span>
        </li>,
        <li
          key={"abd1201a"}
          className={`page-item ${page === totalPage - 3 ? "active" : ""
            }`}
        >
          <span
            className="page-link"
            onClick={pageChange}
            data-page={totalPage - 3}
          >
            {totalPage - 3}
          </span>
        </li>,
        <li
          key={"abd1201"}
          className={`page-item ${page === totalPage - 3 ? "active" : ""
            }`}
        >
          <span
            className="page-link"
            onClick={pageChange}
            data-page={totalPage - 2}
          >
            {totalPage - 2}
          </span>
        </li>,
        <li
          key={"abd1202"}
          className={`page-item ${page === totalPage - 1 ? "active" : ""
            }`}
        >
          <span
            className="page-link"
            onClick={pageChange}
            data-page={totalPage - 1}
          >
            {totalPage - 1}
          </span>
        </li>,
        <li
          key={"abd1210"}
          className={`page-item ${page === totalPage ? "active" : ""
            }`}
        >
          <span
            className="page-link"
            onClick={pageChange}
            data-page={totalPage}
          >
            {totalPage}
          </span>
        </li>
      );
    }
  }
  menuItems.push(
    <li
      key="next"
      className={`page-item ${next > totalPage ? "disabled" : ""}`}
    >
      <span className="zeconcile-btn border-0 transparent" onClick={pageChange} data-page={next}>
        <svg data-page={next} width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.87354 14L11.8735 9L6.87354 4" stroke="#475569" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </span>
    </li>
  );


  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination justify-content-end align-items-center">{menuItems}</ul>
    </nav>
  );
};

export default Pagination;
