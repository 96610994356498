import axios from "axios";
import React, { useState } from "react";
import { isGSTNo } from "../../../common/Utils.js";

const AddBusinessUnitModal = (props) => {
  const [branch, setBranch] = useState({
    branch_name: "",
    email: "",
    gst: "",
    address: "",
    state: "",
    city: "",
    point_of_contact: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const onChangeHandler = (name, value) => {
    setBranch({ ...branch, [name]: value });
    if (name === "gst")
      if (isGSTNo(value)) {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/gst`,
            { gst: value },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            console.log(response);
            if (response.data.status === "Success") {
              const companyInfo =
                response.data.data.lstAppSCommonSearchTPResponse[0];
              console.log(companyInfo);
              const companyAddress = companyInfo["pradr"].addr;
              setBranch({
                ...branch,
                gst: value,
                branch_name: companyInfo.tradeNam,
                state: companyAddress.stcd,
                city: companyAddress.loc,
                address: `${companyAddress.bno ?? ""} ${
                  companyAddress.bnm ?? ""
                } ${companyAddress.flno ?? ""} ${companyAddress.lg ?? ""} ${
                  companyAddress.lt ?? ""
                } ${companyAddress.st ?? ""} ${companyAddress.pncd ?? ""} `,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
  };
  console.log(props.company);
  console.log([props.company]);

  const submitBusinessUnitHandler = () => {
    console.log(branch);
    if (isGSTNo(branch.gst)) {
      try {
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/gst`,
            { params: { gst: branch.gst } },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            console.log(response);
            if (
              response.data.status === "Success" &&
              response.data.data.length === 0
            ) {
              props.setCompany({
                ...props.company,
                branches: [...props.company.branches, branch],
              });
              props.setStep(1);
            } else {
              setError(
                response.data.error || "Given GST number is already registered."
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {}
    } else {
      setError("Enter valid GST number");
    }
  };

  return (
    <div
      className="modal fade show"
      id="error-modal"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <form
          className="modal-content position-relative"
          onSubmit={() => submitBusinessUnitHandler()}
        >
          <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
            <button
              className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => props.setShowEditCompanyModal(false)}
            ></button>
          </div>
          <div className="modal-body p-0">
            <div className="rounded-top-lg py-3 ps-4 pe-6 bg-light">
              <h4 className="mb-1" id="modalExampleDemoLabel">
              {[props.company.name]}
              </h4>
            </div>
            <div className="p-4 pb-0">
              <div className="row">
              <div className="mb-3 w-50">
                <label className="col-form-label">Branch Name</label>
                <input
                  required
                  className="form-control"
                  type="text"
                  onChange={(e) =>
                    onChangeHandler("branch_name", e.target.value)
                  }
                />
                </div>
              <div className="mb-3 w-50">
                <label className="col-form-label">Email</label>
                <input
                  required
                  className="form-control"
                  type="text"
                  onChange={(e) => onChangeHandler("email", e.target.value)}
                />
              </div>
              </div>
              {/* </div> */}
              <div className="row">   
              <div className="mb-3 w-50">
                <label className="col-form-label">GST Number </label>
                <input
                  required
                  className="form-control"
                  type="text"
                  onChange={(e) => onChangeHandler("gst", e.target.value)}
                />
              </div>
              <div className="mb-3 w-50">
                <label className="col-form-label">Address </label>
                <input
                  required
                  className="form-control"
                  type="text"
                  onChange={(e) => onChangeHandler("address", e.target.value)}
                  value={branch.address}
                />
              </div>
              </div>
              <div className="row">
              <div className="mb-3 w-50">
                <label className="col-form-label">City </label>
                <input
                  required
                  className="form-control"
                  type="text"
                  onChange={(e) => onChangeHandler("address", e.target.value)}
                  value={branch.city}
                />
              </div>
              <div className="mb-3 w-50">
                <label className="col-form-label">State </label>
                <input
                  required
                  className="form-control"
                  type="text"
                  onChange={(e) => onChangeHandler("state", e.target.value)}
                  value={branch.state}
                />
              </div>
              </div>
              <div className="row">
              <div className="mb-3 w-50">
                <label className="col-form-label">Point of Contact</label>
                <input
                  required
                  className="form-control"
                  type="text"
                  onChange={(e) =>
                    onChangeHandler("point_of_contact", e.target.value)
                  }
                />
              </div>
              <div className="mb-3 w-50">
                <label className="col-form-label">Mobile</label>
                <input
                  required
                  className="form-control"
                  type="text"
                  onChange={(e) => onChangeHandler("mobile", e.target.value)}
                />
              </div>
              </div>
              {error && (
                <p className="" style={{ color: "red" }}>
                  {error}
                </p>
              )}
            </div>
          </div>
          <div className="modal-footer d-flex justify-content-center">
            <button
              className="btn btn-secondary zeconcile-btn lite"
              type="button"
              data-bs-dismiss="modal"
              onClick={() => props.setStep(1)}
            >
              Cancel
            </button>
            <button className="btn btn-primary zeconcile-btn" type="submit">
              Add Business Unit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddBusinessUnitModal;
