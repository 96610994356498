import React from "react";

function ChatListItem({ alignRight, chat, deleteMessage }) {
  return (
    <div
      className="d-flex p-3"
      style={{
        direction: alignRight ? "rtl" : "ltr",
        listStyleType: "none",
      }}
    >
      <div className="flex-1">
        <div className="w-xxl-75 flex flex-sm-column">
          <div className={`hover-actions-trigger d-flex align-items-center `}>
            <div
              href={chat.link}
              className={`chat-message bg-200  p-2 ${alignRight && "border-chat-msg bg-purple text-white "
                }
                ${!alignRight && "border-chat-msg1  "}`}
              style={{ cursor: "pointer", overflow: "hidden" }}
            >
              {chat.message_type === "text" && chat.message}
              {chat.message_type === "image" && (
                <object
                  className="rounded"
                  data={chat.link}
                  alt=""
                  width={200}
                  aria-label={chat.message}
                ></object>
              )}
              {chat.message_type === "document" && (
                <object
                  className="rounded"
                  data={chat.link}
                  alt=""
                  width={200}
                  aria-label={chat.message}
                ></object>
              )}
            </div>
            <br />
            <ul className="hover-actions position-relative list-inline mb-0 text-400 ms-2">
              <li className="list-inline-item">
                <a
                  className="chat-option"
                  href="#a"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title=""
                  data-bs-original-title="Forward"
                  aria-label="Forward"
                >
                  <svg
                    className="svg-inline--fa fa-share fa-w-16"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="share"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M503.691 189.836L327.687 37.851C312.281 24.546 288 35.347 288 56.015v80.053C127.371 137.907 0 170.1 0 322.326c0 61.441 39.581 122.309 83.333 154.132 13.653 9.931 33.111-2.533 28.077-18.631C66.066 312.814 132.917 274.316 288 272.085V360c0 20.7 24.3 31.453 39.687 18.164l176.004-152c11.071-9.562 11.086-26.753 0-36.328z"
                    />
                  </svg>
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  className="chat-option"
                  href="#f"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title=""
                  data-bs-original-title="Archive"
                  aria-label="Archive"
                >
                  <svg
                    className="svg-inline--fa fa-archive fa-w-16"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="archive"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M32 448c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32V160H32v288zm160-212c0-6.6 5.4-12 12-12h104c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-8zM480 32H32C14.3 32 0 46.3 0 64v48c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16V64c0-17.7-14.3-32-32-32z"
                    />
                  </svg>
                  {/* <span className="fas fa-archive"></span> Font Awesome fontawesome.com */}
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  className="chat-option"
                  href="#b"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title=""
                  data-bs-original-title="Edit"
                  aria-label="Edit"
                >
                  <svg
                    className="svg-inline--fa fa-edit fa-w-18"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="edit"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"
                    />
                  </svg>
                  {/* <span className="fas fa-edit"></span> Font Awesome fontawesome.com */}
                </a>
              </li>
              <li className="list-inline-item">
                <button
                  className="chat-option outline-none text-decoration-none"
                  onClick={() => deleteMessage(chat._id)}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title=""
                  data-bs-original-title="Remove"
                  aria-label="Remove"
                  style={{
                    border: "none",
                    background: "none",
                    "&:hover": { color: "#ff7961 !important" },
                  }}
                >
                  <svg
                    className="svg-inline--fa fa-trash-alt fa-w-14"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="trash-alt"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
                    />
                  </svg>
                  {/* <span className="fas fa-trash-alt"></span> Font Awesome fontawesome.com */}
                </button>
              </li>
            </ul>
          </div>
          <div className="text-400 fs--2">
            <span>
              {new Date(chat.created_at).toLocaleTimeString() +
                " "}
              {/* new Date(chat.created_at).toLocaleDateString()} */}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatListItem;
