

export const isGSTNo = (g) => {
  g = g.replace(/\s/g, "");
  const regex = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/g;
  const found = g.match(regex);
  if (found) {
    if (found.length) {
      return true;
    }
  } else {
    return false;
  }
};

export const formateDate = (dateOriginal) => {
  dateOriginal =
    dateOriginal?.length === 8
      ? dateOriginal?.replace(
          /(\d{2})-(\d{2})-(\d{2})/,
          parseInt("$3") > 22 ? "$1/$2/19$3" : "$1/$2/20$3"
        )
      : dateOriginal?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3");

      dateOriginal =
    dateOriginal?.length === 8
      ? dateOriginal?.replace(
          /(\d{2})\/(\d{2})\/(\d{2})/,
          parseInt("$3") > 22 ? "$2/$1/19$3" : "$2/$1/20$3"
        )
      : dateOriginal?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3");
  console.log(dateOriginal.length);
  // data.inv_date = data.inv_date?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3");
  dateOriginal =  dateOriginal?.replace(/(\d+)(st|nd|rd|th)/, "$1");
  console.log(dateOriginal?.replace(/(\d+)(st|nd|rd|th)/, "$1"));
  let date = new Date(
    dateOriginal?.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
  );
  // console.log("");
  if(!date){
  }
  console.log(date);
  let yyyy = date.getFullYear();
  let mm = date.getMonth() + 1; // Months start at 0!
  let dd = date.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  date = yyyy + "-" + mm + "-" + dd;
  // data.inv_date
  //   ? new Date(data.inv_date).toISOString().slice(0, 10)
  //   : "";
  return date;
};

export const verifySVG = (color) =>{
  const Icon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={color} class="bi bi-patch-check" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0"></path>
      <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911z"></path>
    </svg>
  );
  return Icon
}

