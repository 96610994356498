import React from "react";
import navigationHook from "../../common/hooks";
// import { NavLink } from "react-router-dom";
import axios from "axios";

class SalesInvoices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      baseUrl: "https://zeconcile.thesecondwheel.in/api",
      invoices: [],
      page: 1,
      query: [],
      gst: false,
    };
    localStorage.removeItem("fileKey");
    this.getInvoices();
  }

  getInvoices = async () => {
    try {
      let response = await axios.get(
        "https://zeconcile.thesecondwheel.in/api/whatsapp/files/" +
          this.state.page,
          { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
      );
      let data = response.data.data;
      console.log(data);
      localStorage.setItem("fileKey", data.files[0].key);
      this.setState({
        invoices: data.files,
      });
      this.getParseData();
    } catch (e) {
      console.log(e);
    }
  };

  getParseData = async () => {
    try {
      let key = localStorage.getItem("fileKey")
        ? localStorage.getItem("fileKey")
        : "1660895066168-City_Electronics_Invoice.jpeg";
      let response = await axios({
        method: "POST",
        url: "https://zeconcile.thesecondwheel.in/api/process",
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        data: {
          key: key,
        },
      });
      let data = response.data.data;
      console.log(data);
      this.setState({
        query: data,
        gst: response.data.GST,
      });
    } catch (e) {
      console.log(e);
    }
  };

  keyChange = async (e) => {
    let index = e.target.attributes.getNamedItem("data-key").value;
    console.log(index);
    localStorage.setItem("fileKey", index);
    this.getParseData();
  };

  render() {
    return (
      <>
        <div className="row g-3 m-0 mb-3">
          <div className="col mt-1">
            <div className="card bg-100 shadow-none border py-3">
              <div className="row gx-0 flex-between-center">
                <div className="col-sm-auto d-flex align-items-center">
                  <img
                    className="ms-n2"
                    src="../assets/img/illustrations/crm-bar-chart.png"
                    alt=""
                    width="90"
                  />
                  <div>
                    <h6 className="text-primary fs--1 mb-0">Sales invoices </h6>
                    {/* <h4 className="text-primary fw-bold mb-0">Ze<span className="text-info fw-medium">Concile</span></h4> */}
                  </div>
                  {/* <img className="ms-n4 d-md-none d-lg-block" src="../assets/img/illustrations/crm-line-chart.png" alt="" width="150" /> */}
                </div>
                <div className="col-md-auto p-3">
                  <form className="row align-items-center g-3">
                    <div className="col-auto">
                      {/* <h6 className="text-700 mb-0">Showing Data For: </h6> */}
                    </div>
                    <div className="col-md-auto position-relative">
                      {/* <input className="form-control form-control-sm datetimepicker ps-4 flatpickr-input" id="CRMDateRange" type="text" data-options="{&quot;mode&quot;:&quot;range&quot;,&quot;dateFormat&quot;:&quot;M d&quot;,&quot;disableMobile&quot;:true , &quot;defaultDate&quot;: [&quot;Apr 21&quot;, &quot;Apr 28&quot;] }" readonly="readonly" /> <span className="fas fa-calendar-alt text-primary position-absolute top-50 translate-middle-y ms-2"> </span> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-3 m-0 mb-3 card bg-100 shadow-none border py-3">
          <div className="table-responsive scrollbar">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">FileName</th>
                  <th scope="col">Url</th>
                  {/* <th className="text-end" scope="col">Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {this.state.invoices.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <span onClick={this.keyChange} data-key={item.key}>
                        {item.filename}
                      </span>
                    </td>
                    <td>{item.location}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row g-3 m-0 mb-3 card bg-100 shadow-none border py-3">
          <div className="table-responsive scrollbar">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Query</th>
                  <th scope="col">Ans.</th>
                  {/* <th className="text-end" scope="col">Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {this.state.query.map((item, index) => (
                  <tr key={index}>
                    <td>{item.query}</td>
                    <td>{item.answer ? item.answer[0].text : ""}</td>
                  </tr>
                ))}
                {this.state.gst ? (
                  <tr key="-2">
                    <td>GST</td>
                    <td>{this.state.gst}</td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default navigationHook(SalesInvoices);
