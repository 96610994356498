import React, { useEffect, useState } from "react";

import axios from "axios";
// import SaveEditCompanyDetailsModal from "./save_edit_company_details";
import EditBusinessUnitModal from "./edit_company_details";
import AddBusinessUnitModal from "./add_business_unit";
import ViewBusinessUnitModal from "./view_company_details";

const EditCompanyModal = ({
  show,
  companyId,
  setShowEditCompanyModal,
  setDisplayAlert2,
}) => {
  const [company, setCompany] = useState();
  const [selectedBranch, setSelectedBranch] = useState();
  const [step, setStep] = useState(0);

  useEffect(() => {
    const getCompany = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/company/${companyId}`,
          {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
        );
        const {
          data: { data },
        } = response;
        setCompany(data);
        setSelectedBranch(data.branches[0]);
        setStep(1);
      } catch (err) {
        console.log(err);
      }
    };
    getCompany();
  }, [companyId]);

  const clear = () => {
    setStep(1);
  };

  const setCustomShowEditCompanyModal = (value) => {
    setStep(1);
    setShowEditCompanyModal(value);
  };

  const renderContent = () => {
    if (step === 1) {
      return (
        <ViewBusinessUnitModal
          branch={selectedBranch}
          company={company}
          setSelectedBranch={setSelectedBranch}
          setShowEditCompanyModal={setCustomShowEditCompanyModal}
          setStep={setStep}
          setDisplayAlert2={setDisplayAlert2}
          clear={clear}
        />
      );
    } else if (step === 2) {
      return (
        <EditBusinessUnitModal
          branch={selectedBranch}
          company={company}
          setCompany={setCompany}
          setShowEditCompanyModal={setCustomShowEditCompanyModal}
          setStep={setStep}
          setDisplayAlert2={setDisplayAlert2}
          clear={clear}
        />
      );
    } else if (step === 3) {
      return (
        <AddBusinessUnitModal
          company={company}
          setCompany={setCompany}
          setShowEditCompanyModal={setCustomShowEditCompanyModal}
          setStep={setStep}
        />
      );
    }
  };

  return <React.Fragment>{show ? renderContent() : ""}</React.Fragment>;
};

export default EditCompanyModal;
