import { useState } from "react";
import { isGSTNo } from "../../../common/Utils";
import axios from "axios";

const AddCompanyNameModal = ({
  companyName,
  setCompanyName,
  setShowAddCompanyModal,
  setStep,
}) => {
  const [gstValidation, setgstValidation] = useState("");

  const checkGstRegistered = () => {
    if (isGSTNo(companyName)) {
      try {
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/gst`,
            { params: { gst: companyName } },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            console.log(response);
            if (
              response.data.status === "Success" &&
              response.data.data.length === 0
            ) {
              setStep(2);
            } else {
              setgstValidation(
                response.data.error || "Given GST number is already registered."
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {}
    } else {
      setgstValidation("Enter valid GST number");
    }
  };

  return (
    <div
      className="modal fade show"
      id="error-modal"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{ maxWidth: "500px", height: "10vh" }}
      >
        <div className="modal-content position-relative">
          <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
            <button
              className="clr-purple transparent btn btn-sm mt-2 me-2 d-flex flex-center"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setShowAddCompanyModal(false)}
            >
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.2752 12.1249L19.2871 7.12488C19.5065 6.90541 19.6298 6.60774 19.6298 6.29736C19.6298 5.98698 19.5065 5.68931 19.2871 5.46984C19.0676 5.25037 18.7699 5.12708 18.4595 5.12708C18.1492 5.12708 17.8515 5.25037 17.632 5.46984L12.6318 10.4816L7.63167 5.46984C7.41219 5.25037 7.11452 5.12708 6.80413 5.12708C6.49375 5.12708 6.19608 5.25037 5.9766 5.46984C5.75713 5.68931 5.63383 5.98698 5.63383 6.29736C5.63383 6.60774 5.75713 6.90541 5.9766 7.12488L10.9884 12.1249L5.9766 17.125C5.86736 17.2334 5.78065 17.3623 5.72147 17.5043C5.6623 17.6463 5.63184 17.7987 5.63184 17.9525C5.63184 18.1064 5.6623 18.2587 5.72147 18.4008C5.78065 18.5428 5.86736 18.6717 5.9766 18.78C6.08495 18.8893 6.21386 18.976 6.35589 19.0352C6.49793 19.0943 6.65027 19.1248 6.80413 19.1248C6.958 19.1248 7.11034 19.0943 7.25237 19.0352C7.39441 18.976 7.52332 18.8893 7.63167 18.78L12.6318 13.7683L17.632 18.78C17.7404 18.8893 17.8693 18.976 18.0113 19.0352C18.1533 19.0943 18.3057 19.1248 18.4595 19.1248C18.6134 19.1248 18.7657 19.0943 18.9078 19.0352C19.0498 18.976 19.1787 18.8893 19.2871 18.78C19.3963 18.6717 19.483 18.5428 19.5422 18.4008C19.6014 18.2587 19.6318 18.1064 19.6318 17.9525C19.6318 17.7987 19.6014 17.6463 19.5422 17.5043C19.483 17.3623 19.3963 17.2334 19.2871 17.125L14.2752 12.1249Z" fill="#5945B3" />
              </svg>
            </button>
          </div>
          <div className="modal-body p-0">
            <div className="rounded-top-lg py-3 ps-4 pe-6 bg-light">
              <h4 className="mb-1" id="modalExampleDemoLabel">
                New company
              </h4>
            </div>
            <div className="p-4 pb-0">
              <div className="mb-3">
                <label className="col-form-label">Parent Company GST</label>
                <input
                  placeholder="Enter Comany GST number"
                  className="form-control"
                  id="recipient-name"
                  type="text"
                  value={companyName}
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                  }}
                />
              </div>
              {gstValidation && (
                <p className="" style={{ color: "red" }}>
                  {gstValidation}
                </p>
              )}
            </div>
          </div>
          <div className="modal-footer mx-auto">
            <button
              className="zeconcile-btn transparent btn btn-secondary"
              type="button"
              data-bs-dismiss="modal"
              onClick={() => setShowAddCompanyModal(false)}
            >
              Cancel
            </button>
            <button
              className="zeconcile-btn btn btn-primary"
              type="button"
              onClick={() => checkGstRegistered()}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCompanyNameModal;
