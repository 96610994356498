import React from "react";
import navigationHook from "../../common/hooks";
import axios from "axios";
import fileDownload from "js-file-download";
import { formateDate } from "../../common/Utils.js";
import Select from "react-select";
import './invoice.css';
import AddCompanyModal from "../companies/add-debt-cred/add_company";
import Breadcrumb from "../../common/breadcrums";
import { isGSTNo } from "../../common/Utils";
import TextInput from "../../common/components/TextInput";


class InvoicesDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      baseUrl: process.env.REACT_APP_BASE_URL, //"http://localhost:8100/api",
      invoice: {},
      filetype: "image",
      invDate: "",
      company: "",
      loader: true,
      cred_debt_loader: true,
      currentInvoice: {},
      invoices: [],
      selectOptions: [],
      selectCredDebt: [],
      isNewCredDebt: false,
      selectOptionsBranch: [],
      allClients: [],
      branchIndex: 0,
      credDebtIndex: 0,
      branchUpdateBody: {},
      credDebtUpdateBody: {},
      message: "",
      defaultComapny: "",
      defaultCompany_address: "",
      defaultBranch: "",
      defaultBranchLabel: "",
      dummytext: "",
      cred_debt_type: "",
      ledger_cred_debt_type: "",
      isChecked: false,
      isEditable: true,
      width: "100%",
      height: "100%",
      scale: 1,
      CustomerLabel: "Buyer name",
      CustomerGstLabel: "Customer GST",
      showAddCompanyModal: false,
      setShowAddCompanyModal: false,
      showEditCompanyModal: false,
      setShowEditCompanyModal: false,
      displayAlert: {
        display: false,
        status: false,
        message: "",
      },
      displayAlert2: {
        display: false,
        status: false,
        message: "",
      },
      ledgerArray: {},
      ledger: {},
      filteredLedgers: [],
      filteredTypes: [],
      companyId: {},
      ledgerEntryCreditTotal: 0,
      ledgerEntryDebitTotal: 0,
    };
    // console.log(props);
  }

  handleEditModalClick = (id) => {
    this.setState({
      companyId: id,
      showEditCompanyModal: true,
    });
  };
  get_url_extension = (url) => {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  async componentDidMount() {
    try {
      await this.getInvoice();
    } catch (e) {
      console.log(e);
    }
  }
  getLedgers = async () => {
    // console.log("Company Ledger");
    // console.log(this.state.company);
    try {
      let body = {
        company_id: this.state.company,
        type: this.state.party_type,
      };
      let apiUrl = "/company/ledgers/";

      let response = await axios.post(
        `${this.state.baseUrl}${apiUrl}` + 1,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      let data = response.data.data;
      console.log(data);
      if (data.length) {
        this.setState(
          {
            ledger: data,
            filteredTypes: data.filter((ledger) => ledger.ledger_type),
          },
          () => {
            const uniqueLedgerTypes = [
              ...new Set(
                this.state.filteredTypes.map((ledger) => ledger.ledger_type)
              ),
            ];

            this.setState({
              filteredLedgers: uniqueLedgerTypes,
            });
          }
        );
      }
    } catch (e) {
      console.log(e);
    }
  };
  getAllInvoices = () => {
    axios
      .get(`${this.state.baseUrl}/invoice-all/` + this.state.company, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        let data = response.data.data;
        const index = data.findIndex(
          (item) => item._id === this.state.invoice._id
        );

        this.setState({ ...this.state, currentInvoice: index, invoices: data });
        this.checkImageExtension();
      })
      .catch((e) => {
        console.log(e);
      })
      .finally((f) => {});
  };

  getAllClients = (opt) => {
    // if (opt !== undefined && opt !== null && opt.length >= 3) {
    axios
      .get(`${this.state.baseUrl}/company/all?name=${opt}`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        let data = response.data.data;
        const options = data.map((item, index) => ({
          value: item._id,
          label: item.name,
          branchIndex: index,
          gst: item.branches[0].gst,
          state: item.branches[0].state,
          address: item.branches[0].address,
        }));

        this.setState(
          {
            ...this.state,
            selectOptions: options,
            allClients: data,
            defaultComapny:
              opt !== "" || opt !== null ? opt : this.state.defaultComapny,
          },
          () => {
            this.getBranch();
          }
        );
      })
      .catch((e) => {
        console.log(e);
      })
      .finally((f) => { });
    // } else {
    // console.log("loading");
    // }
  };
  getCreditorDebtorsByGST = (gst) => {
    if (gst !== undefined && gst !== null && isGSTNo(gst)) {

      let query = {
        gst: gst,
        type:
          this.state.invoice.inv_type == 2
            ? "Sundry Debtors"
            : "Sundry Creditors",
      };

      const queryString = Object.keys(query)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`
        )
        .join("&");

      axios
        .get(`${this.state.baseUrl}/ledger/searchbygst?${queryString}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })

        .then((response, err) => {
          if (
            response.data.status === "400" ||
            response.data.status === "Failure"
          ) {
            this.gstDetailsUpdateHandler(gst, true);
          } else {
            this.setState({
              cred_debt_loader: false,
            });
            console.log("Ledger Logs ");
            let data = response.data.data;
            let _invoice = this.state.invoice;
            _invoice.counter_party = data.name;
            _invoice.counter_party_id = data._id;

            console.log(_invoice.counter_party_id);
            console.log(_invoice.counter_party);

            // _invoice._id = data._id;
            _invoice.counter_party_state = data.state;
            _invoice.counter_party_address = data.address;
            this.setState(
              {
                invoice: _invoice,
                isNewCredDebt: false,
              },
              () => {
                this.setState({
                  cred_debt_loader: true,
                });
              }
            );
            console.log(this.state.invoice);
          }
        })

        .catch((e) => {
          this.gstDetailsUpdateHandler(gst, true);
        });
    } else {
    }
  };

  handleEditClick = () => {
    this.setState({
      isEditable: false,
    });
  };
  handleCloseEditClick = () => {
    this.setState({
      isEditable: true,
    });
  };
  getCreditorDebtorsByLedgers = (opt) => {
    if (opt !== undefined && opt !== null && opt.length >= 3) {
      console.log(this.state.ledger_cred_debt_type);
      let body = {
        type:
          this.state.invoice.inv_type == 2
            ? "Sundry Debtors"
            : "Sundry Creditors",
        company_id: this.state.company,
        search: opt,
      };
      console.log("Cred Debt API Call ");
      console.log(body);
      axios
        .post(`${this.state.baseUrl}/company/ledgers/` + 1, body, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })

        .then((response) => {
          let data = response.data.data;
          const options = data.map((item, index) => ({
            value: item._id,
            label: item.name,
            gst: item.gst,
            address: item.address,
            state: item.state,
            index: index,
          }));
          this.setState({
            selectCredDebt: options,
          });
          console.log("got the option");
          console.log(this.state.selectCredDebt);
        })
        .catch((e) => {
          // console.log(e);
        });
    } else {
      // console.log("loading");
    }
  };
  getCreditorDebtors = (opt) => {
    if (opt !== undefined && opt !== null && opt.length >= 3) {
      let body = {
        type: this.state.cred_debt_type,
        company_id: this.state.company,
        search: opt,
      };
      console.log("Cred Debt API Call ");
      console.log(body);
      axios
        .post(`${this.state.baseUrl}/company/cred-debt/` + 1, body, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })

        .then((response) => {
          let data = response.data.data;
          const options = data.map((item, index) => ({
            value: item._id,
            label: item.name,
            gst: item.gst,
            address: item.address,
            state: item.state,
            index: index,
          }));
          this.setState({
            selectCredDebt: options,
          });
          console.log(
            "got the option" + JSON.stringify(this.state.selectCredDebt)
          );
        })
        .catch((e) => {
          // console.log(e);
        });
    } else {
      // console.log("loading");
    }
  };
  getLedgersOption = (opt) => {
    if (opt !== undefined && opt !== null && opt.length >= 3) {
      let body = {
        company_id: this.state.company,
        search: opt,
      };

      axios
        .post(`${this.state.baseUrl}/company/ledgers/` + 1, body, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          let data = response.data.data;
          console.log(data);
          const options = data.map((item, index) => ({
            value: item._id,
            label: item.name,
            LedgerName: item.name,
            Parent: item.ledger_type,
            Entry_Type: "Debit",
            Amount: 4500,
          }));
          console.log(options);
          this.setState({
            selectLedger: options,
          });
          console.log(
            "got the option" + JSON.stringify(this.state.selectLedger)
          );
        })
        .catch((e) => {
          // console.log(e);
        });
    } else {
      // this.getLedgers();
      // console.log("loading");
    }
  };

  deleteRow = (index) => {
    this.setState((prevState) => ({
      invoice: {
        ...prevState.invoice,
        product_table: prevState.invoice.product_table.filter(
          (_, i) => i !== index
        ),
      },
    }));
  };
  addRow = () => {
    const { invoice } = this.state;
    const productTable = invoice?.product_table || [];
    const newRow = {
      name: " ",
      hsn: " ",
      CGST: " ",
      IGST: " ",
      SGST: " ",
      qty: " ",
      total: " ",
    };

    const availableFields =
      invoice?.product_table.length > 0
        ? Object.keys(invoice?.product_table[0])
        : Object.keys(newRow);
    const filteredRow = {};
    Object.keys(newRow).forEach((key) => {
      if (availableFields.includes(key)) {
        filteredRow[key] = newRow[key];
      }
    });

    this.setState({
      invoice: { ...invoice, product_table: [...productTable, newRow] },
    });
  };

  handleInputChangeForClient = async (event) => {
    this.state.branchIndex = event.branchIndex;

    let companybranch = this.state.branchUpdateBody;
    let client_company = event.label;
    companybranch["client_company"] = client_company;

    let client_company_id = event.value;
    companybranch["client_company_id"] = client_company_id;

    let _invoice = this.state.invoice;
    _invoice.client_company_id = event.value;
    _invoice.client_company = event.label;
    _invoice.client_company_state = event.state;
    _invoice.client_company_address = event.address;
    _invoice.buyer_gst = event.gst;
    await this.setState({
      loader: true,
      branchUpdateBody: companybranch,
      invoice: _invoice,
    });
  };

  handleInputChangeForCredDebt = async (event) => {
    await this.setState({
      loader: false,
    });
    console.log("Event Catch");
    // console.log(event);
    let invoice = this.state.invoice;
    if (event.gst && event.gst.trim() !== "") {
      invoice.counter_party_gst = event.gst;
    } else {
      console.log("event.gst does not have value");
    }

    // invoice.counter_party_gst = event.gst;
    invoice.counter_party_id = event.value;
    invoice.counter_party_address = event.address;
    invoice.counter_party_state = event.state;
    invoice.counter_party = event.label;
    let entry = invoice.ledger_entry;
    entry.LedgerEntriesList[0].LedgerName = event.label;
    invoice.ledger_entry = entry;

    await this.setState(
      {
        // loader: true,
        invoice: invoice,
        // credDebtUpdateBody: credDebt,
      },
      () => {
        this.setState({ loader: true });
      }
    );
    // console.log("handle input ", this.state.credDebtUpdateBody);
  };
  handleInputChangeForBranch = async (event) => {
    let companybranch = this.state.branchUpdateBody;

    let company_branch_id = event.value;
    companybranch["company_branch_id"] = company_branch_id;
    await this.setState({
      loader: true,
      branchUpdateBody: companybranch,
    });
  };

  getBranch = () => {
    let branchOption = this.state.allClients[
      this.state.branchIndex
    ]?.branches.map((branch) => ({
      value: branch._id,
      label: branch.branch_name,
    }));

    let b_label = "";
    branchOption?.map((branch) => {
      if (branch.value === this.state.defaultBranch) {
        b_label = branch.label;
      }
      return branch;
    });

    this.setState({
      loader: true,
      selectOptionsBranch: branchOption,
      defaultBranchLabel: b_label,
    });
  };

  handleCheckClick = () => {
    const checkbox = document.querySelector('input[name="isChecked"]');
    if (checkbox && !checkbox.checked) {
      this.setState({ isChecked: false });
    } else {
      this.setState({ isChecked: true });
    }
  };

  getInvoice = async () => {
    try {
      let response = await axios.get(
        `${this.state.baseUrl}/invoice/single/${this.props.params.id}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      let data = response.data.data;

      var clientName = data.client_company;
      // var address = data._invoice.client_company_address;
      let defaultBranch = data.company_branch_id;
      let nameLabel =
        data.inv_type === 1 || data.inv_type === "1" ? "Seller" : "Buyer";
      let gstLabel =
        data.inv_type === 2 || data.inv_type === "2"
          ? "Seller GST"
          : "Customer GST";
      let cred_debt_type = "";
      let ledger_cred_debt_type = "";
      if (data.inv_type > 0) {
        cred_debt_type =
          data.inv_type === 2 || data.inv_type === "2" ? "debtor" : "creditor";
        ledger_cred_debt_type =
          data.inv_type === 2 || data.inv_type === "2"
            ? "Sundry Debtors"
            : "Sundry Creditors";
      }
      console.log("data", data);
      let date = null;
      if (data.inv_date) {
        date = formateDate(data.inv_date);
        data.inv_date = date;
      }
      // console.log(date);
      console.log(" Ledger Entry : " + JSON.stringify(data.ledger_entry));
      // try {
      let EntryType = data.inv_type == 2 ? "Credit" : "Debit";
      let keyForLedgerCheck = [];
      let prdLeadgerload = true;
      if (
        !data.ledger_entry ||
        data.ledger_entry.LedgerEntriesList == null ||
        data.ledger_entry.LedgerEntriesList === " " ||
        (Array.isArray(data.ledger_entry.LedgerEntriesList) &&
          data.ledger_entry.LedgerEntriesList.length === 0)
      ) {
        console.log("if  Condition ");
        let customValue = [
          {
            LedgerName: data.counter_party || "Select Ledger",
            Parent: "Indirect Expenses",
            Entry_Type: data.inv_type == 2 ? "Debit" : "Credit",
            Amount: data.grand_total,
          },
          // {
          //   LedgerName: "Select Ledger",
          //   Parent: "Indirect Expenses",
          //   Entry_Type: EntryType,
          //   Amount: data.subtotal,
          // },
        ];
        if (data.taxes[0].CGST > 0) {
          customValue.push({
            LedgerName: "CGST",
            Parent: "Unsecured Loans",
            Entry_Type: EntryType,
            Amount: data.taxes ? data.taxes[0].CGST : 0,
          });
        }

        if (data.taxes[0].SGST > 0) {
          customValue.push({
            LedgerName: "SGST",
            Parent: "Unsecured Loans",
            Entry_Type: EntryType,
            Amount: data.taxes ? data.taxes[0].SGST : 0,
          });
        }
        if (data.taxes[0].IGST > 0) {
          customValue.push({
            LedgerName: "IGST",
            Parent: "Taxes",
            Entry_Type: EntryType,
            Amount: data.taxes ? data.taxes[0].IGST : 0,
          });
        }
        let counterGT =
          data.subtotal +
          data.taxes[0].CGST +
          data.taxes[0].SGST +
          data.taxes[0].IGST;
        if (data.taxes && data.grand_total !== counterGT) {
          customValue.push({
            LedgerName: "Round Off",
            Parent: "Unsecured Loans",
            Entry_Type: EntryType,
            Amount: (data.grand_total - counterGT).toFixed(2),
          });
        }
        let vTypeName = "Undefined";
        if (data.inv_type > 0) {
          vTypeName =
            data.inv_type == 2
              ? "Sales"
              : data.inv_type == 1
              ? "Purchase"
              : "Expense";
        }

        const updatedInvoice = {
          ledger_entry: {
            Date: date,
            VoucherTypeName: vTypeName,
            VoucherNumber: data.inv_no,
            BillingCompany: this.state.defaultComapny,
            LedgerEntriesList: customValue,
          },
        };

        data.ledger_entry = updatedInvoice.ledger_entry;
        console.log(
          "if  Condition Ledger Entry  : " + JSON.stringify(data.ledger_entry)
        );
      } else {
        console.log("else  Condition ");
        data.ledger_entry.LedgerEntriesList.map((item) => {
          keyForLedgerCheck.push(item.LedgerName);
        });

        data.product_table?.map((item) => {
          if (keyForLedgerCheck.includes(item.name)) {
            prdLeadgerload = false;
          }
        });
      }
      this.setState(
        {
          loader: true,
          invoice: data,
          invDate: date,
          CustomerLabel: nameLabel,
          CustomerGstLabel: gstLabel,
          company: data.client_company_id,
          defaultComapny: clientName,
          defaultBranch: defaultBranch,
          cred_debt_type: cred_debt_type,
          ledger_cred_debt_type: ledger_cred_debt_type,

          selectCredDebt: data.counter_party
            ? [
                {
                  value: data.counter_party_id,
                  label: data.counter_party,
                },
              ]
            : [],
        },
        () => {
          this.getAllInvoices();
          this.getLedgers();
          this.updateTotalAmountOfLedgerEntry();
          if (prdLeadgerload) {
            this.getLedgersEntryForProducts(this.props.params.id, EntryType);
          }
          if (clientName) {
            this.getCompanyDetails();
            this.getAllClients(clientName);
          } else {
            this.getCompanyDetails();
            // console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&");
          }
        }
      );
      console.log("Updated Invoice  : " + JSON.stringify(this.state.invoice));
    } catch (e) {
      // console.log(e);
    }
  };

  getLedgersEntryForProducts = async (invoice_id, type = "Debit") => {
    try {
      let response = await axios.get(
        `${this.state.baseUrl}/invoice/goods-ledger-entry/${invoice_id}?type=${type}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (response.data.status === "Success") {
        console.log(response.data.data);
        let invoiceLedger = this.state.invoice.ledger_entry;
        invoiceLedger.LedgerEntriesList.push(...response.data.data);
        let _invoice = this.state.invoice;
        _invoice.ledger_entry = invoiceLedger;
        await this.setState(
          {
            loader: true,
            invoice: _invoice,
          },
          () => {
            console.log("update Total entry amounts");
            this.updateTotalAmountOfLedgerEntry();
          }
        );
      }
    } catch (e) {
      console.log(e);
    }
  };
  gstDetailsUpdateHandler = async (gst, is_counter = false) => {
    // console.log(gst);
    if (isGSTNo(gst)) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/gst`,
          { gst: gst },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          // console.log(response);
          let _invoice = this.state.invoice;
          if (response.data.status === "Success") {
            const companyInfo =
              response.data.data.lstAppSCommonSearchTPResponse[0];
            // console.log(companyInfo);
            const companyAddress = companyInfo["pradr"].addr;
            let party_name = companyInfo.tradeNam;
            let state = companyAddress.stcd;
            let city = companyAddress.loc;
            let address = `${companyAddress.bno ?? ""} ${
              companyAddress.bnm ?? ""
            } ${companyAddress.flno ?? ""} ${companyAddress.lg ?? ""} ${
              companyAddress.lt ?? ""
            } ${companyAddress.st ?? ""} ${companyAddress.stcd + "-" ?? ""}${
              companyAddress.pncd ?? ""
            } `;

            if (is_counter) {
              _invoice.counter_party = party_name;
              _invoice.counter_party_address = address;
              _invoice.counter_party_state = state;
              _invoice.counter_party_city = city;
              // console.log("this is Creditor" + this.state.invoice.counter_party);
            } else {
              _invoice.client_company = party_name;
              _invoice.client_company_address = address;
              _invoice.client_company_state = state;
            }

            this.setState({
              invoice: _invoice,
              isClientGstChange: false,
              isCounterGstChange: false,
              // isNewCredDebt: true
            });
            this.state.isNewCredDebt = true;
            // console.log(this.state.isNewCredDebt);
            if (this.state.isNewCredDebt) {
              // console.log("This is New Creditor");
              const confirmed = window.confirm(
                "Would You Like To Add New " +
                  this.state.cred_debt_type +
                  " As A '" +
                  this.state.invoice.counter_party + "'"
              );
              if (confirmed) {
                this.state.showAddCompanyModal = true;
              }
            }
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    } else {
      alert("Invalid GST number. Please enter valid value.");
    }
  };
  handleInputChangeForLedger = async (selectedOption, name) => {
    // Assuming this.state.ledgerArray is an array of ledger entries in your state
    const updatedLedgerArray = this.state.ledgerArray;
    console.log(selectedOption);
    console.log(name);

    // If entry not found, create a new one
    let invoiceLedger = this.state.invoice.ledger_entry;
    if (name > -1) {
      invoiceLedger.LedgerEntriesList[name].LedgerName =
        selectedOption.LedgerName;
      invoiceLedger.LedgerEntriesList[name].Parent = selectedOption.Parent;
    } else {
      // Retrieve the existing entry
      const existingEntry = updatedLedgerArray[0];

      // Append the selected option to the LedgerEntriesList array
      existingEntry.LedgerEntriesList.push({
        ...selectedOption,
        index: existingEntry.LedgerEntriesList.length, // Replace with the actual property name
      });
    }
    let _invoice = this.state.invoice;
    _invoice.ledger_entry = invoiceLedger;
    if (name === 0) {
      // _invoice.counter_party_gst = selectedOption.gst;
      _invoice.counter_party_id = selectedOption.value;
      // _invoice.counter_party_address = selectedOption.address;
      // _invoice.counter_party_state = selectedOption.state;
      _invoice.counter_party = selectedOption.LedgerName;
    }
    // Update the state with the modified ledgerArray
    await this.setState({
      loader: true, // Assuming you want to set loader to true
      ledgerArray: updatedLedgerArray,
      invoice: _invoice,
    });
    console.log("Invoice ledger");
    console.log(this.state.invoice.ledgerArray);
  };

  handleInputAmountChangeForLedgerEntry = async (value, index) => {
    console.log(value);
    console.log(index);

    let invoiceLedger = this.state.invoice.ledger_entry;
    invoiceLedger.LedgerEntriesList[index].Amount = value;
    let _invoice = this.state.invoice;
    _invoice.ledger_entry = invoiceLedger;
    // Update the state with the modified ledgerArray
    await this.setState(
      {
        loader: true,
        invoice: _invoice,
      },
      () => {
        console.log("update Total entry amounts");
        this.updateTotalAmountOfLedgerEntry();
      }
    );
  };

  addRemoveForLedgerEntry = async (type, index) => {
    console.log(type);
    console.log(index);
    await this.setState({
      loader: false,
    });
    let invoiceLedger = this.state.invoice.ledger_entry;
    if (index >= 0 && type === "remove") {
      invoiceLedger.LedgerEntriesList.splice(index, 1);
    } else if (type === "Credit" || type === "Debit") {
      invoiceLedger.LedgerEntriesList.push({
        LedgerName: "Select Ledger",
        Parent: "Expense",
        Entry_Type: type,
        Amount: 0,
      });
    }
    let _invoice = this.state.invoice;
    _invoice.ledger_entry = invoiceLedger;
    console.log(invoiceLedger);
    // Update the state with the modified ledgerArray
    await this.setState(
      {
        loader: true,
        invoice: _invoice,
      },
      () => {
        console.log("update Total entry amounts");
        this.updateTotalAmountOfLedgerEntry();
      }
    );
  };

  updateTotalAmountOfLedgerEntry = async () => {
    let creditTotal = 0;
    let debitTotal = 0;
    if (this.state.invoice.ledger_entry?.LedgerEntriesList?.length > 0) {
      let entry = this.state.invoice.ledger_entry;
      entry.LedgerEntriesList = await entry?.LedgerEntriesList.map((item) => {
        if (item.Entry_Type === "Credit") {
          creditTotal += Number(item.Amount);
        } else {
          debitTotal += Number(item.Amount);
        }
        return item;
      });
    }
    await this.setState({
      ledgerEntryCreditTotal: creditTotal.toFixed(2),
      ledgerEntryDebitTotal: debitTotal.toFixed(2),
    });
  };

  getCompanyDetails = async () => {
    try {
      if (this.state.company) {
        // console.log("called me !!!!!!!!!!");
        let response = await axios.get(
          `${this.state.baseUrl}/company/${this.state.company}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        let data = response.data.data;
        // // console.log(data);
        if (data) {
          let _invoice = this.state.invoice;
          _invoice.client_company_id = data._id;
          _invoice.client_company = data.name;
          _invoice.client_company_state = data.branches[0].state;
          _invoice.client_company_address = data.branches[0].address;
          // _invoice.buyer_gst = data.branches[0].gst;
          this.setState({
            invoice: _invoice,
          });
        }
        // this.getAllClients('');
      }
    } catch (e) {
      // console.log(e);
    }
  };
  updateInvoice = async (e) => {
    try {
      let body = this.state.invoice;

      delete body["_id"];
      delete body["__v"];
      delete body["doc_url"];
      delete body["createdAt"];
      delete body["updatedAt"];
      delete body["taxes"][0]["_id"];
      body.ledger_entry.LedgerEntriesList =
        body.ledger_entry?.LedgerEntriesList?.filter((obj) => obj.Amount !== 0);
      console.log(body);

      let response = await axios.put(
        `${this.state.baseUrl}/invoice/update/${this.props.params.id}`,
        body,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      let data = response.data;
      // console.log(response);
      if (data.status === "Success") {
        let inv = this.state.invoice;
        inv.status = body.status;

        this.getInvoice();
      }
    } catch (e) {
      // console.log(e);
    }
  };

  updateStatus = async (e) => {
    try {
      // console.log(e.target.attributes.getNamedItem("status").value);
      let status = e.target.attributes.getNamedItem("status").value;
      let entry = this.state.invoice.ledger_entry;
      let flg = false;
      entry.LedgerEntriesList = await entry?.LedgerEntriesList.map((item) => {
        if (item.LedgerName === "Select Ledger") {
          flg = true;
          alert("Please select ledger entry names");
        }
        return item;
      });
      if (flg) {
        return false;
      }
      let body = {
        status: status,
      };
      let response = await axios.put(
        `${this.state.baseUrl}/invoice/${this.props.params.id}`,
        body,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      let data = response.data;

      if (data.status === "Success") {
        // console.log(data.status);
        let inv = this.state.invoice;
        inv.status = body.status;
        this.setState(
          {
            invoice: inv,
          },
          () => {
            this.updateInvoice(inv);
          }
        );
        // console.log(this.state.invoice);
      }
      // console.log(data);
    } catch (e) {
      // console.log(e);
    }
  };

  handleInputChange = async (event) => {
    this.state.branchIndex = event.branchIndex;
    // console.log(this.state.branchIndex);
    const target = event.target;
    // console.log("target", event);
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const comments = target.comments;
    let invoice = this.state.invoice;
    invoice[[name]] = value;
    invoice[[comments]] = target.comments;

    let nameLabel =
      invoice["inv_type"] === 1 || invoice["inv_type"] === "1"
        ? "Seller"
        : "Buyer";
    let cred_debt_type = "";
    let ledger_cred_debt_type = "";

    if (invoice["inv_type"] > 0) {
      cred_debt_type =
        invoice["inv_type"] === 2 || invoice["inv_type"] === "2"
          ? "debtor"
          : "creditor";
      ledger_cred_debt_type =
        invoice["inv_type"] === 2 || invoice["inv_type"] === "2"
          ? "Sundry Debtors"
          : "Sundry Creditors";
    }
    let gstLabel =
      invoice["inv_type"] === 2 || invoice["inv_type"] === "2"
        ? "Seller GST"
        : "Customer GST";

    let vTypeName =
      invoice["inv_type"] == 2
        ? "Sales"
        : invoice["inv_type"] == 1
        ? "Purchase"
        : "Expense";
    if (invoice["inv_type"] > 0) {
      invoice["ledger_entry"].VoucherTypeName = vTypeName;
      invoice["ledger_entry"].LedgerEntriesList = invoice[
        "ledger_entry"
      ].LedgerEntriesList.map((item, index) => {
        let _item = item;
        _item.Entry_Type = invoice["inv_type"] == 2 ? "Credit" : "Debit";
        if (index == 0) {
          _item.Entry_Type = invoice["inv_type"] == 2 ? "Debit" : "Credit";
        }
        return _item;
      });
    }
    console.log(invoice);
    await this.setState({
      invoice: invoice,
      CustomerLabel: nameLabel,
      CustomerGstLabel: gstLabel,
      cred_debt_type: cred_debt_type,
      ledger_cred_debt_type: ledger_cred_debt_type,
      isClientGstChange: name === "seller_gst" ? true : false,
      isCounterGstChange: name === "counter_party_gst" ? true : false,
    });
    // console.log(invoice["inv_type"] + nameLabel)
    // console.log("handle input ", this.state.invoice);
  };

  handleProductChange = async (event) => {
    const target = event.target;
    const index = event.target.getAttribute("data-index");
    const value = target.type === "checkbox" ? target.checked : target.value;

    const name = target.name;
    let invoice = this.state.invoice;
    invoice.product_table[index][[name]] = value;
    // console.log(invoice.product_table);
    await this.setState({
      invoice: invoice,
    });
    // console.log(invoice);
  };

  handleInputTaxChange = async (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let invoice = this.state.invoice;
    invoice.taxes[0][[name]] = value;
    await this.setState({
      invoice: invoice,
    });
    // console.log(invoice);
  };

  handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
        headers: { Accept: "*/*", "Access-Control-Allow-Origin": "*" },
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };
  // handleBack = () => {
  //   const history = useHistory();
  //   history.goBack();
  // };
  nextInvoice = async () => {
    this.checkImageExtension();
    const index = this.state.invoices.findIndex(
      (item) => item._id === this.state.invoice._id
    );
    // console.log(index);
    // console.log(this.state.invoices.length);
    if (index >= 0 && index + 1 < this.state.invoices.length) {
      const data = this.state.invoices[index + 1];
      let date = formateDate(data.inv_date);
      this.props.history.push(`/invoice/${data._id}`);
      this.setState({
        loader: true,
        invoice: data,
        invDate: date,
        company: data.client_company_id,
        currentInvoice: index + 1,
      });
    }
  };

  prevInvoice = async () => {
    this.checkImageExtension();
    const index = this.state.invoices.findIndex(
      (item) => item._id === this.state.invoice._id
    );
    // console.log(index - 1);
    // console.log(this.state.invoices.length);
    if (index > 0) {
      const data = this.state.invoices[index - 1];
      let date = formateDate(data.inv_date);
      this.props.history.push(`/invoice/${data._id}`);
      this.setState({
        loader: true,
        invoice: data,
        invDate: date,
        company: data.client_company_id,
        currentInvoice: index - 1,
      });
    }
  };

  checkImageExtension = (url) => {
    let imageUrl = this.state.invoice.doc_url;
    // console.log("url extention function call", imageUrl);
    let image = imageUrl.split(/[#?]/)[0].split(".").pop().trim();
    // console.log(image);
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  hadleZoomInZoomOut = (zoom) => {
    // console.log(this.refs.refImage.width);
    if (zoom) {
      this.setState({
        scale: this.state.scale * 1.1,
        width: this.state.width * 0.9,
        height: this.state.height * 0.9,
      });
    } else {
      this.setState({
        scale: this.state.scale * 0.9,
        width: this.state.width * 1.1,
        height: this.state.height * 1.1,
      });
    }
  };

  render() {
    // const ledgerGroups = this.state.filteredLedgers.map((ledgerType, index) => (
    //   <optgroup key={index} label={ledgerType}>
    //     {/* Filter the ledgers based on the current ledger type */}
    //     {this.state.ledger
    //       .filter((option) => option.ledger_type === ledgerType)
    //       .map((option, subIndex) => (
    //         <option key={subIndex} value={option.name}>
    //           {option.name}
    //         </option>
    //       ))}
    //   </optgroup>
    // ));
    const { isChecked, showAddCompanyModal } = this.state;

    const labelText = isChecked
      ? "Hide Description of Goods"
      : "Hide Description of Goods";

    return (
      <>
        <div className="" style={{ minWidth: "1847px" }}>
          {showAddCompanyModal && (
            <AddCompanyModal
              show={showAddCompanyModal}
              companyId={this.state.company}
              cred_debt_type={this.state.ledger_cred_debt_type}
              cred_debt_name={this.state.invoice.counter_party}
              cred_debt_gst={this.state.invoice.counter_party_gst}
              cred_debt_state={this.state.invoice.counter_party_state}
              cred_debt_address={this.state.invoice.counter_party_address}
              cred_debt_city={this.state.invoice.counter_party_city}
              setShowAddCompanyModal={(value) =>
                this.setState({ showAddCompanyModal: value })
              }
              setDisplayAlert={(value) =>
                this.setState({ displayAlert: value })
              }
            />
          )}

          <Breadcrumb
            paths={[
              { label: this.state.defaultComapny, active: false },
              { label: "Invoice", active: true },
            ]}
          ></Breadcrumb>

          <div className="row mt-3">
            <h1
              style={{
                fontSize: "26px",
                color: "#475569",
                fontWeight: "700",
              }}
            >
              <button
                className="btn justify-content-left"
                onClick={this.handleBack}
              >
                {"<"}
              </button>
              Invoice
            </h1>
            <div className="col-6">
              {this.state.invoice.doc_url ? (
                <div
                  className=""
                  style={{
                    minWidth: "100%",
                    display: "inline-block",
                    position: "relative",
                    overflow: "auto",
                  }}
                >
                  <iframe
                    title="invoice"
                    src={this.state.invoice.doc_url}
                    style={{
                      minWidth: "100%",
                      maxHeight: 800,
                      WebkitTransform: `scale(${this.state.scale})`,
                      transform: `scale(${this.state.scale})`,
                      WebkitTransformOrigin: "0 0",
                      transformOrigin: "0 0",
                      height: 800,
                      overflow: "inherit",
                    }}
                    ref="refImage"
                  ></iframe>
                </div>
              ) : this.state.loader === true ? (
                <div className="loader-wrapper">
                  <div className="loader"></div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-6">
              <div className="w-100">
                <div className="d-flex gap-2">
                  <div className="w-50 mb-3 me-2">
                    <label className="form-label" htmlFor="split-login-email">
                      Select Invoice Type
                    </label>
                    <select
                      className="form-select"
                      name="inv_type"
                      value={this.state.invoice.inv_type}
                      onChange={this.handleInputChange}
                      aria-label="State"
                    >
                      <option key="0" value="0">
                        Select type
                      </option>
                      <option key="2" value="2">
                        Sale
                      </option>
                      <option key="1" value="1">
                        Purchase
                      </option>
                      <option key="3" value="3">
                        Expense
                      </option>
                    </select>
                  </div>
                  <div className=" mb-3">
                    {/* <label className="form-label" htmlFor="split-login-email">
                      Invoice Date
                    </label>
                    <input
                      className="form-control"
                      id="split-login-email"
                      type="date"
                      name="inv_date"
                      onChange={this.handleInputChange}
                      value={this.state.invoice.inv_date}
/> */}
                    <TextInput
                            label='Invoice Date'
                            id="inv_date"
                            type="date"
                            name="inv_date"
                            value={this.state.invoice.inv_date}
                            onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="d-flex gap-2">
                    <div className="">
                      <label className="form-label" htmlFor="split-login-email">
                        Invoice No.
                      </label>
                      <input
                        className="form-control"
                        id="split-login-password"
                        name="inv_no"
                        onChange={this.handleInputChange}
                        value={this.state.invoice.inv_no}
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="box">
                    <div className="d-flex justify-content-space-between w-100">
                      <label
                        className="form-label"
                        htmlFor="split-login-password"
                      >
                        GSTIN/UIN
                      </label>
                      {/* {this.state.isClientGstChange ? (
                        <div
                          className="p ms-10 text-primary f-14 text-end"
                          type="button"
                          onClick={() => {
                            this.gstDetailsUpdateHandler(
                              this.state.invoice.seller_gst,
                              false
                            );
                          }}
                        >
                          Update
                        </div>
                      ) : (
                        ""
                      )} */}
                    </div>
                    <input
                      className="form-control d-block"
                      id="split-login-email"
                      name="seller_gst"
                      type="text"
                      onChange={this.handleInputChange}
                      value={this.state.invoice.seller_gst}
                      // disabled={true}
                    />

                    <div className="d-flex justify-content-space-between">
                      <label
                        className="form-label me-4"
                        htmlFor="split-login-password"
                      >
                        Company Name
                      </label>
                    </div>

                    {this.state.defaultComapny ? (
                      <Select
                        onInputChange={(opt) => this.getAllClients(opt)}
                        onChange={this.handleInputChangeForClient}
                        aria-label="State"
                        labelKey="name"
                        value={this.state.value}
                        options={this.state.selectOptions}
                        defaultValue={{
                          value: this.state.company,
                          label: this.state.defaultComapny,
                        }}
                        isClearable={true}
                      />
                    ) : (
                      ""
                    )}
                    {!this.state.defaultComapny ? (
                      <Select
                        onInputChange={(opt) => this.getAllClients(opt)}
                        onChange={this.handleInputChangeForClient}
                        aria-label="State"
                        labelKey="name"
                        value={this.state.value}
                        options={this.state.selectOptions}
                        isClearable={true}
                      />
                    ) : (
                      ""
                    )}
                    <br />
                    <label
                      className="form-label"
                      htmlFor="split-login-password"
                    >
                      Company Address
                    </label>
                    <input
                      className="form-control"
                      id="counter_party"
                      type="text"
                      name="client_company_address"
                      onChange={this.handleInputChange}
                      value={this.state.invoice?.client_company_address}
                    />
                    <label
                      className="form-label"
                      htmlFor="split-login-password"
                    >
                      State
                    </label>
                    <input
                      className="form-control d-block"
                      id="state-email"
                      name="company_state"
                      type="text"
                      onChange={this.handleInputChange}
                      value={this.state.invoice?.client_company_state}
                    />
                  </div>
                  <div className="box ms-3">
                    <div className="d-flex justify-content-space-between w-100">
                      <label
                        className="form-label"
                        htmlFor="split-login-password"
                      >
                        GSTIN/UIN
                      </label>
                      {this.state.isCounterGstChange ? (
                        <div
                          className="p ms-10 text-primary f-14 text-end"
                          type="button"
                          onClick={() => {
                            this.getCreditorDebtorsByGST(
                              this.state.invoice.counter_party_gst
                            );
                          }}
                        >
                          Update
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <input
                      className="form-control d-block"
                      id="split-login-email"
                      name="counter_party_gst"
                      type="text"
                      onChange={this.handleInputChange}
                      value={
                        this.state.invoice.counter_party_gst ||
                        this.state.invoice.buyer_gst
                      }
                    />
                    {/* <p>{this.state.invoice.buyer_gst}</p> */}
                    <div className="d-flex justify-content-space-between">
                      <label
                        className="form-label me-5"
                        htmlFor="split-login-password"
                      >
                        {this.state.CustomerLabel} Name
                      </label>
                    </div>
                    {this.state.invoice.counter_party_id && this.state.cred_debt_loader ? (
                      <Select
                        onInputChange={(opt) =>
                          this.getCreditorDebtorsByLedgers(opt)
                        }
                        aria-label="State"
                        labelKey="name"
                        value={this.state.value}
                        options={this.state.selectCredDebt}
                        onChange={this.handleInputChangeForCredDebt}
                        defaultValue={{
                          label: this.state.invoice.counter_party,
                          value: this.state.invoice.counter_party_id,
                        }}
                        isClearable={true}
                      />
                    ) : (
                      ""
                    )}
                    {!this.state.invoice.counter_party_id && this.state.cred_debt_loader ? (
                      <Select
                        onInputChange={(opt) =>
                          this.getCreditorDebtorsByLedgers(opt)
                        }
                        aria-label="State"
                        labelKey="name"
                        value={this.state.label}
                        options={this.state.selectCredDebt}
                        onChange={this.handleInputChangeForCredDebt}
                        defaultValue={{
                          label: this.state.invoice.counter_party,
                          value: this.state.invoice.counter_party_id,
                        }}
                        isClearable={true}
                      />
                    ) : (
                      ""
                    )}
                    <br />
                    <label
                      className="form-label"
                      htmlFor="split-login-password"
                    >
                      {this.state.CustomerLabel} Address
                    </label>
                    <input
                      className="form-control"
                      id="counter_party"
                      type="text"
                      name="counter_party_address"
                      onChange={this.handleInputChange}
                      value={this.state.invoice.counter_party_address}
                    />
                    <label
                      className="form-label"
                      htmlFor="split-login-password"
                    >
                      State
                    </label>
                    <input
                      className="form-control d-block"
                      id="split-login-email"
                      name="counter_party_state"
                      type="text"
                      onChange={this.handleInputChange}
                      value={this.state.invoice.counter_party_state}
                    />
                    <div className="d-flex justify-content-end">
                      {this.state.type === "" && !this.state?.company ? null : (
                        <span
                          className="p ms-10 clr-purple f-14 text-end d-none"
                          type="button"
                          onClick={() =>
                            this.setState({ showAddCompanyModal: true })
                          }
                        >
                          Add New
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-space-between">
                  <div
                    className="form-check mt-3"
                    onClick={this.handleCheckClick}
                  >
                    <input
                      className="me-2 px-2 form-check-input rounded"
                      onClick={this.handleCheckClick}
                      type="checkbox"
                      name="isChecked"
                      id="flexCheckDefault"
                    />
                    <label className="font-weight-bold " htmlFor="isChecked">
                      {labelText}
                    </label>
                  </div>

                  <div className="me-5 d-none">
                    <label htmlFor="ledgers">Ledger Type</label>
                    <select
                      className="form-control eye"
                      id="split-login-password"
                      onChange={(e) =>
                        this.setState({ ledgers_type: e.target.value }, () => {
                          this.setState({
                            msg: "",
                          });
                        })
                      }
                      value={this.state.ledgers_type}
                    >
                      <option value="">Select Ledger</option>
                      {this.state.filteredLedgers.map((ledger, index) => (
                        <option key={index} value={ledger.name}>
                          {ledger}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div key="product-11122">
                  {this.state.isChecked ? (
                    false
                  ) : (
                    <div className="scrollbar w-100" key="product-111223">
                      {this.state.invoice?.product_table?.map((item, index) =>
                        // index > 0 && !item.new && (
                        item.name ||
                        item.hsn ||
                        item.CGST ||
                        item.IGST ||
                        item.SGST ||
                        item.qty ||
                        item.total ? (
                          <div
                            className="d-flex gap-2 mt-3 ms-auto"
                            key={"prd-row" + index}
                          >
                            <div className="w-50 mb-3" id="goods">
                              <label
                                className="form-label"
                                htmlFor="split-login-password"
                              >
                                Description of Goods
                              </label>
                              <textarea
                                cols="25"
                                className="form-control text-area"
                                id="split-login-password"
                                type="text"
                                name="name"
                                onChange={this.handleProductChange}
                                value={item.name}
                                data-index={index}
                              />
                            </div>
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="split-login-password"
                              >
                                HSN/SAC
                              </label>
                              <input
                                className="form-control"
                                id="split-login-password"
                                type="text"
                                name="hsn"
                                onChange={this.handleProductChange}
                                value={item.hsn}
                                data-index={index}
                              />
                            </div>

                            {!item?.CGST ? null : (
                              <div className="mb-3">
                                <div className="d-flex justify-content-between">
                                  <label
                                    className="form-label"
                                    htmlFor="split-login-password"
                                  >
                                    CGST
                                  </label>
                                </div>
                                <input
                                  className="form-control"
                                  id="split-login-password"
                                  type="number"
                                  name="CGST"
                                  onChange={this.handleProductChange}
                                  value={item?.CGST}
                                  data-index={index}
                                />
                              </div>
                            )}
                            {!item.IGST && !item?.tax ? null : (
                              <div className="mb-3">
                                <div className="d-flex justify-content-between">
                                  <label
                                    className="form-label"
                                    htmlFor="split-login-password"
                                  >
                                    IGST
                                  </label>
                                </div>
                                <input
                                  className="form-control"
                                  id="split-login-password"
                                  type="number"
                                  name="IGST"
                                  onChange={this.handleProductChange}
                                  value={item.IGST}
                                  data-index={index}
                                />
                              </div>
                            )}
                            {!item?.SGST ? null : (
                              <div className="mb-3">
                                <div className="d-flex justify-content-between">
                                  <label
                                    className="form-label"
                                    htmlFor="split-login-password"
                                  >
                                    SGST
                                  </label>
                                </div>
                                <input
                                  className="form-control"
                                  id="split-login-password"
                                  type="number"
                                  name="SGST"
                                  onChange={this.handleProductChange}
                                  value={item?.SGST}
                                  data-index={index}
                                />
                              </div>
                            )}
                            {/* {item.qty ? null :( */}
                            <div className="mb-3">
                              <div className="d-flex justify-content-between">
                                <label
                                  className="form-label"
                                  htmlFor="split-login-password"
                                >
                                  Quantity
                                </label>
                              </div>
                              <input
                                className="form-control"
                                id="split-login-password"
                                type="text"
                                name="qty"
                                onChange={this.handleProductChange}
                                value={item.qty}
                                data-index={index}
                              />
                            </div>

                            <div className="mb-3">
                              <div className="d-flex justify-content-between">
                                <label
                                  className="form-label"
                                  htmlFor="split-login-password"
                                >
                                  Amount
                                </label>
                              </div>
                              <input
                                className="form-control"
                                id="split-login-password"
                                type="text"
                                name="subtotal"
                                onChange={this.handleProductChange}
                                value={item.total || item.subtotal}
                                data-index={index}
                              />
                            </div>

                            <div className="mb-3">
                              <div className="d-flex justify-content-between mt-3">
                                <div
                                  onClick={() => this.deleteRow(index)}
                                  className="border-0 bg-transparent"
                                >
                                  <svg
                                    width="25"
                                    height="25"
                                    viewBox="0 0 25 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5.19629 5.32178V22.3218H20.1963V5.32178H5.19629Z"
                                      stroke="#EF4444"
                                      strokeWidth="2"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M10.6963 10.3218V16.8218"
                                      stroke="#EF4444"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M14.6963 10.3218V16.8218"
                                      stroke="#EF4444"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M2.69629 5.32178H22.6963"
                                      stroke="#EF4444"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M8.69629 5.32178L10.3408 2.32178H15.0848L16.6963 5.32178H8.69629Z"
                                      stroke="#EF4444"
                                      strokeWidth="2"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null
                      )}
                    </div>
                  )}
                </div>
                <div>
                  {this.state.isChecked ? (
                    false
                  ) : (
                    <button
                      className="btn d-flex goods mt-3 mb-3"
                      type="button"
                      name="submit"
                      status="1"
                      onClick={this.addRow}
                    >
                      <svg
                        width="19"
                        height="18"
                        viewBox="0 0 19 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.18677 1V17M17.1868 9L1.18677 9"
                          stroke="#5945B3"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span className="ps-2">Add Goods</span>
                    </button>
                  )}
                </div>

                <div className="d-flex gap-2 mt-3 ms-auto">
                  <div className="w-50 mb-3">
                    <h4 className="mt-4">TOTAL</h4>
                  </div>

                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <label
                        className="form-label"
                        htmlFor="split-login-password"
                      >
                        Sub total
                      </label>
                    </div>
                    <input
                      className="form-control"
                      id="split-login-password"
                      type="number"
                      name="subtotal"
                      onChange={this.handleInputChange}
                      value={this.state.invoice.subtotal}
                    />
                  </div>
                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <label
                        className="form-label"
                        htmlFor="split-login-password"
                      >
                        IGST
                      </label>
                    </div>
                    <input
                      className="form-control"
                      id="split-login-password"
                      type="number"
                      name="IGST"
                      onChange={this.handleInputTaxChange}
                      value={
                        this.state.invoice.taxes
                          ? this.state.invoice.taxes[0].IGST
                          : 0
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <label
                        className="form-label"
                        htmlFor="split-login-password"
                      >
                        SGST
                      </label>
                    </div>
                    <input
                      className="form-control"
                      id="split-login-password"
                      type="number"
                      name="SGST"
                      onChange={this.handleInputTaxChange}
                      value={
                        this.state.invoice.taxes
                          ? this.state.invoice.taxes[0].SGST
                          : 0
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <label
                        className="form-label"
                        htmlFor="split-login-password"
                      >
                        CGST
                      </label>
                    </div>
                    <input
                      className="form-control"
                      id="split-login-password"
                      type="number"
                      name="CGST"
                      onChange={this.handleInputTaxChange}
                      value={
                        this.state.invoice.taxes
                          ? this.state.invoice.taxes[0].CGST
                          : 0
                      }
                    />
                  </div>

                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <label
                        className="form-label"
                        htmlFor="split-login-password"
                      >
                        Amount
                      </label>
                    </div>
                    <input
                      className="form-control"
                      id="split-login-password"
                      type="number"
                      name="grand_total"
                      onChange={this.handleInputChange}
                      value={this.state.invoice.grand_total}
                    />
                  </div>
                </div>
                {/* className="" */}
                {this.state.invoice["inv_type"] !== 0 && //this.state.loader &&
                this.state.invoice["inv_type"] !== "0" ? (
                  this.state.isEditable ? (
                    <button
                      className="btn p-0 position-absolute d-flex justify-content-end edit-btn"
                      onClick={this.handleEditClick}
                    >
                      <img
                        src="https://zeconcile1.s3.ap-southeast-1.amazonaws.com/1692254231510-8666681_edit_icon.svg"
                        alt=""
                      />
                      Edit Entries
                    </button>
                  ) : (
                    <button
                      className="btn p-0 position-absolute d-flex justify-content-end edit-btn"
                      onClick={this.handleCloseEditClick}
                    >
                      <img
                        src="https://zeconcile1.s3.ap-southeast-1.amazonaws.com/1692260812588-icons8-cancel.svg"
                        alt=""
                      />
                    </button>
                  )
                ) : (
                  ""
                )}
                <div>
                  {this.state.invoice["inv_type"] !== 0 && //this.state.loader &&
                  this.state.invoice["inv_type"] !== "0" ? (
                    <div className="box w-100">
                      <div className="d-flex col-lg-12">
                        <table className="table w-100">
                          <tbody>
                            <tr className="text-dark">
                              <th>Particulars</th>
                              <th className="text-center">Debit</th>
                              <th className="text-center">Credit</th>
                              <th className="text-center"></th>
                            </tr>
                            {/* <tr>
                            <td className="px-0">
                              <div className="p-0 d-flex align-items-center gap-2" >
                                <span>Dr.</span>
                                <div className="d-flex ">
                                  <Select
                                    onInputChange={(opt) =>
                                      this.getCreditorDebtorsByLedgers(opt)
                                    }
                                    onChange={this.handleInputChangeForCredDebt}
                                    aria-label="State"
                                    // labelKey="name"
                                    isDisabled={this.state.isEditable}
                                    value={this.state.selectCredDebt}
                                    options={this.state.selectCredDebt}
                                    defaultValue={{
                                      value: this.state.invoice.counter_party_id,
                                      label: this.state.invoice.counter_party,
                                    }}
                                    />
                                </div>
                              </div>
                            </td>
                            <td className="text-center">
                              <input
                                className="text-center"
                                type="text"
                                value={`${this.state.invoice.grand_total}`}
                              />
                            </td>
                            <td></td>
                          </tr> */}
                            {this.state.loader &&
                              this.state.invoice.ledger_entry?.LedgerEntriesList.map(
                                (ledger, index) => (
                                  <tr key={index}>
                                    <td className="px-0 w-50">
                                      <div className="p-0 d-flex align-items-center gap-2">
                                        <span>
                                          {ledger.Entry_Type === "Credit"
                                            ? "Cr."
                                            : "Dr."}
                                        </span>
                                        <div className="d-flex1 w-100 ">
                                          <Select
                                            onInputChange={(opt) =>
                                              this.getLedgersOption(opt)
                                            }
                                            className="border-0 font-weight-bold no-dropdown"
                                            options={this.state.selectLedger}
                                            onChange={(selectedOption) =>
                                              this.handleInputChangeForLedger(
                                                selectedOption,
                                                index
                                              )
                                            }
                                            defaultValue={{
                                              value:
                                                this.state.invoice.ledger_entry
                                                  .LedgerEntriesList[index]
                                                  .LedgerName, // Replace with the actual default value
                                              label:
                                                this.state.invoice.ledger_entry
                                                  .LedgerEntriesList[index]
                                                  .LedgerName,
                                            }}
                                            isDisabled={this.state.isEditable}
                                            // value={ledger.LedgerName}
                                            isClearable={true}
                                          />
                                        </div>
                                      </div>
                                      {/* <p>{ledger}</p> */}
                                    </td>
                                    {ledger.Entry_Type === "Credit" ? (
                                      <>
                                        <td className="text-center"></td>
                                        <td className="text-center">
                                          <input
                                            className="text-center"
                                            type="text"
                                            onChange={(e) => {
                                              this.handleInputAmountChangeForLedgerEntry(
                                                e.target.value,
                                                index
                                              );
                                            }}
                                            value={`${ledger.Amount}`}
                                            disabled={this.state.isEditable}
                                          />
                                        </td>
                                      </>
                                    ) : (
                                      <>
                                        <td className="text-center">
                                          <input
                                            className="text-center"
                                            type="text"
                                            onChange={(e) => {
                                              this.handleInputAmountChangeForLedgerEntry(
                                                e.target.value,
                                                index
                                              );
                                            }}
                                            value={` ${ledger.Amount}`}
                                            disabled={this.state.isEditable}
                                          />
                                        </td>
                                        <td className="text-center"></td>
                                      </>
                                    )}
                                    <td className="text-center">
                                      {index >= 0 ? (
                                        <button
                                          disabled={this.state.isEditable}
                                          className="bg-white border-0 text-danger"
                                          onClick={(e) => {
                                            this.addRemoveForLedgerEntry(
                                              "remove",
                                              index
                                            );
                                          }}
                                        >
                                          x
                                        </button>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  </tr>
                                )
                              )}
                            <tr>
                              <td className="font-weight-bold">Narration</td>
                              {/* <tr className="border border-black remove_border"> */}
                              <td className="text-center border border-black remove_border">
                                <input
                                  className="text-center"
                                  type="text"
                                  value={`${this.state.ledgerEntryDebitTotal}`}
                                  disabled={this.state.isEditable}
                                />
                              </td>
                              <td className="text-center border border-black remove_border">
                                <input
                                  className="text-center"
                                  type="text"
                                  value={`${this.state.ledgerEntryCreditTotal}`}
                                  disabled={this.state.isEditable}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="3" className="px-0 w-50">
                                <button
                                  disabled={this.state.isEditable}
                                  className="btn btn-primary d-inline-flex align-items-center gap-2 zeconcile-btn purple"
                                  onClick={(e) => {
                                    this.addRemoveForLedgerEntry("Credit", 0);
                                  }}
                                >
                                  Add Credit entry
                                </button>
                                <button
                                  disabled={this.state.isEditable}
                                  className="btn btn-primary d-inline-flex align-items-center gap-2 zeconcile-btn purple mx-1"
                                  onClick={(e) => {
                                    this.addRemoveForLedgerEntry("Debit", 0);
                                  }}
                                >
                                  Add Debit entry
                                </button>
                              </td>
                            </tr>
                            {/* </tr> */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    // Code to execute if inv_type is not 1
                    <div className="box w-100">
                      <div className="text-danger">
                        Please select invoice type to Approve/un-approve invoice
                      </div>
                    </div>
                  )}
                </div>
                <div className="d-flex gap-2 pt-3">
                  <div className="w-100 mb-3">
                    <div className="d-flex justify-content-between">
                      <label className="form-label">Comments*</label>
                    </div>
                    <input
                      className="comments w-100"
                      id="split-login-password"
                      type="text"
                      name="comments"
                      disabled={this.state.invoice.status !== 0 ? true : false}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
                <div
                  className={`alert alert-danger ${
                    this.state.ledgerEntryCreditTotal !==
                    this.state.ledgerEntryDebitTotal
                      ? ""
                      : "d-none"
                  }`}
                >
                  Please settle the "Narration" total amount to
                  approve/un-approve Invoice.
                </div>
                <div
                  className={`${
                    this.state.invoice.status !== 0 ? "" : "d-none"
                  }`}
                >
                  <span className="alert">
                    Invoice is
                    {this.state.invoice.status === 1 ? "Approved" : "Rejected"}
                  </span>
                </div>
                <div
                  className={`d-flex gap-2 justify-content-space-between scrollbar w-100`}
                >
                  <div className="d-flex">
                    <button
                      type="button"
                      disabled={this.state.currentInvoice === 0}
                      className="btn btn-primary d-flex align-items-center gap-2 mx-3 zeconcile-btn purple"
                      onClick={this.prevInvoice}
                    >
                      <svg
                        width="19"
                        height="18"
                        viewBox="0 0 19 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.8735 4L6.87354 9L11.8735 14"
                          stroke="#475569"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{" "}
                      Prev
                    </button>
                    <button
                      type="button"
                      disabled={
                        this.state.currentInvoice ===
                        this.state.invoices.length - 1
                      }
                      className="btn btn-primary d-flex align-items-center gap-2 zeconcile-btn purple"
                      onClick={this.nextInvoice}
                    >
                      Next{" "}
                      <svg
                        width="19"
                        height="18"
                        viewBox="0 0 19 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.87354 14L11.8735 9L6.87354 4"
                          stroke="#475569"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="d-flex">
                    <button
                      // disabled={this.state.ledgerEntryCreditTotal !== this.state.ledgerEntryDebitTotal ? true : false}
                      onClick={this.updateInvoice}
                      className="btn btn-primary d-block gap-2 align-items-center mx-1 zeconcile-btn purple"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <svg
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.40723 3.9375C2.40723 3.31618 2.91091 2.8125 3.53223 2.8125H13.0128L15.9072 5.51494V15.1875C15.9072 15.8088 15.4036 16.3125 14.7822 16.3125H3.53223C2.91091 16.3125 2.40723 15.8088 2.40723 15.1875V3.9375Z"
                          stroke="#5945B3"
                          strokeWidth="1.5"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.16034 2.8125L9.15723 5.58172C9.15723 5.70919 8.98934 5.8125 8.78223 5.8125H5.78223C5.57511 5.8125 5.40723 5.70919 5.40723 5.58172V2.8125H9.16034Z"
                          stroke="#5945B3"
                          strokeWidth="1.5"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M3.53223 2.8125H13.0128"
                          stroke="#5945B3"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5.40723 10.3125H12.9072"
                          stroke="#5945B3"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5.40723 13.3125H9.16034"
                          stroke="#5945B3"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span className="ms-1"> Save Draft</span>
                    </button>
                    {/* Download Invoice details */}
                    {/* <button
                      onClick={this.getAllInvoices}
                      className="btn btn-primary d-block gap-2 mx-3 zeconcile-btn purple"
                      href={this.state.invoice.doc_url}
                      target="_blank"
                      rel="noreferrer"
                      download
                      alt=""
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.04639 7.73438L8.99951 10.6875L11.9526 7.73438"
                          stroke="#5945B3"
                          strokeWidth="1.125"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.99951 2.8103V10.6853"
                          stroke="#5945B3"
                          strokeWidth="1.125"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15.7495 9.5625V14.625C15.7495 14.7742 15.6902 14.9173 15.5848 15.0227C15.4793 15.1282 15.3362 15.1875 15.187 15.1875H2.81201C2.66283 15.1875 2.51975 15.1282 2.41426 15.0227C2.30878 14.9173 2.24951 14.7742 2.24951 14.625V9.5625"
                          stroke="#5945B3"
                          strokeWidth="1.125"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span className="ms-1">Download</span>
                    </button> */}
                    {this.state.invoice.status === 0 ||
                    this.state.invoice.status === 2 ? (
                      <button
                        className="btn btn-success d-flex gap-2 approve align-items-center  "
                        type="button"
                        name="submit"
                        status="1"
                        disabled={
                          this.state.ledgerEntryCreditTotal !==
                          this.state.ledgerEntryDebitTotal
                            ? true
                            : false
                        }
                        onClick={this.updateStatus}
                      >
                        <svg
                          status="1"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.7743 5.59231C13.7082 5.52562 13.6295 5.47268 13.5428 5.43656C13.4561 5.40043 13.3631 5.38184 13.2691 5.38184C13.1752 5.38184 13.0822 5.40043 12.9955 5.43656C12.9088 5.47268 12.8301 5.52562 12.7639 5.59231L7.46291 10.9005L5.23576 8.66623C5.16709 8.59989 5.08601 8.54772 4.99717 8.51271C4.90833 8.4777 4.81346 8.46053 4.71799 8.46218C4.62251 8.46383 4.5283 8.48427 4.44072 8.52234C4.35314 8.5604 4.27392 8.61534 4.20758 8.68402C4.14123 8.7527 4.08907 8.83378 4.05406 8.92262C4.01904 9.01146 4.00187 9.10633 4.00353 9.2018C4.00518 9.29728 4.02562 9.3915 4.06368 9.47907C4.10175 9.56665 4.15669 9.64587 4.22537 9.71222L6.95771 12.4446C7.02386 12.5113 7.10256 12.5642 7.18927 12.6003C7.27598 12.6365 7.36898 12.6551 7.46291 12.6551C7.55684 12.6551 7.64985 12.6365 7.73656 12.6003C7.82327 12.5642 7.90196 12.5113 7.96811 12.4446L13.7743 6.6383C13.8466 6.57167 13.9042 6.4908 13.9436 6.40079C13.9831 6.31078 14.0034 6.21357 14.0034 6.11531C14.0034 6.01704 13.9831 5.91984 13.9436 5.82983C13.9042 5.73981 13.8466 5.65894 13.7743 5.59231Z"
                            fill="white"
                          />
                        </svg>
                        <span status="1"> Approve</span>
                      </button>
                    ) : (
                      ""
                    )}
                    {this.state.invoice.status === 1 ? (
                      <button
                        className="btn btn-danger d-flex gap-2 approve align-items-center  "
                        type="button"
                        name="submit"
                        status="0"
                        disabled={
                          this.state.ledgerEntryCreditTotal !==
                          this.state.ledgerEntryDebitTotal
                            ? true
                            : false
                        }
                        onClick={this.updateStatus}
                      >
                        <svg
                          status="0"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.7743 5.59231C13.7082 5.52562 13.6295 5.47268 13.5428 5.43656C13.4561 5.40043 13.3631 5.38184 13.2691 5.38184C13.1752 5.38184 13.0822 5.40043 12.9955 5.43656C12.9088 5.47268 12.8301 5.52562 12.7639 5.59231L7.46291 10.9005L5.23576 8.66623C5.16709 8.59989 5.08601 8.54772 4.99717 8.51271C4.90833 8.4777 4.81346 8.46053 4.71799 8.46218C4.62251 8.46383 4.5283 8.48427 4.44072 8.52234C4.35314 8.5604 4.27392 8.61534 4.20758 8.68402C4.14123 8.7527 4.08907 8.83378 4.05406 8.92262C4.01904 9.01146 4.00187 9.10633 4.00353 9.2018C4.00518 9.29728 4.02562 9.3915 4.06368 9.47907C4.10175 9.56665 4.15669 9.64587 4.22537 9.71222L6.95771 12.4446C7.02386 12.5113 7.10256 12.5642 7.18927 12.6003C7.27598 12.6365 7.36898 12.6551 7.46291 12.6551C7.55684 12.6551 7.64985 12.6365 7.73656 12.6003C7.82327 12.5642 7.90196 12.5113 7.96811 12.4446L13.7743 6.6383C13.8466 6.57167 13.9042 6.4908 13.9436 6.40079C13.9831 6.31078 14.0034 6.21357 14.0034 6.11531C14.0034 6.01704 13.9831 5.91984 13.9436 5.82983C13.9042 5.73981 13.8466 5.65894 13.7743 5.59231Z"
                            fill="white"
                          />
                        </svg>
                        <span status="0"> Unapprove</span>
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default navigationHook(InvoicesDetail);
