import React, { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import ChatListItem from "./ChatListItem.js";
import ChatUserListItem from "./ChatUserListItem.js";
// import { v4 as uuidv4 } from "uuid";

const initialState = {
  currentRoom: { to: "" },
  to: "",
  chatList: [],
  userList: [],
  userListName: [],
  lastMessages: {}
};

const socket = io(`${process.env.REACT_APP_BACK_URL}`, {
  autoConnect: false,
});

function ChatPage() {
  const [state, setState] = useState(initialState);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const messagesEndRef = useRef(null);
  const messageRef = useRef(null);
  const phoneRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    socket.open();

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [state.chatList]);

  useEffect(() => {
    socket.on("connect", () => {
      setIsConnected(true);
    });

    socket.on("disconnect", () => {
      setIsConnected(false);
    });

    socket.emit("getUserList", {
      user: localStorage.getItem('ZC_org_WA_no_id')//process.env.REACT_APP_WHATS_APP_SENDER_ID,
    });

    socket.emit("getUserLastMessages", {
      user: localStorage.getItem('ZC_org_WA_no_id'),
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("pong");
    };
  }, []);

  if (isConnected) {
    socket.on("messageSended", (arg) => {
      console.log(arg);
      socket.emit("getChatList", { user: state.currentRoom.to });
    });

    socket.on("roomCreated", (arg) => {
      console.log(arg);
    });

    socket.on("chatListFetched", (arg) => {
      if (arg.status === 0) {
        setState((preState) => ({ ...preState, chatList: arg.data }));
      }
    });

    socket.on("userListFetched", (arg) => {
      if (arg.status === 0) {
        console.log(arg.data);
        setState((preState) => ({
          ...preState,
          userList: arg.data?.mobile,
          userListName: arg.data?.result
        }));
      }
    });

    socket.on("userLastMessagesFetched", (arg) => {
      console.log(arg.data);
      if (arg.status === 0) {
        setState((preState) => ({
          ...preState,
          lastMessages: arg.data
        }));
      }
    });

    socket.on("messageDeleted", (arg) => {
      if (arg.status === 0) {
        socket.emit("getChatList", { user: state.currentRoom.to });
      }
    });

    socket.on("chatFileUploaded", (arg) => {
      if (arg.status === 0) {
        socket.emit("getChatList", { user: state.currentRoom.to });
      }
    });
  }

  const sendMessage = (e) => {
    e.preventDefault();
    if (isConnected) {
      socket.emit("sendMessage", {
        message: {
          body: messageRef.current.value,
        },
        to: state.currentRoom.to,
        from: localStorage.getItem('ZC_org_WA_no_id'),
        type: "text",
      });
      messageRef.current.value = "";
    }
  };

  const onDeleteMessage = (id) => {
    console.log("delete mes", id);
    if (isConnected) {
      socket.emit("deleteMessage", { id: id });
    }
  };

  const setCurrenRoom = (phoneNum) => {
    if (phoneNum.length >= 10) {
      console.log(phoneNum);
      socket.emit("getChatList", { user: phoneNum });
      let name = state.userListName.find(
        (username) => username.phone === phoneNum
      )?.name;
      setState((preState) => ({
        ...preState,
        currentRoom: {
          to: phoneNum,
          name: name,
        },
        to: "",

      }));
      if (!state.userList.includes(phoneNum)) {
        setState((prev) => ({
          ...state,
          userList: prev.userList.concat(phoneNum),
        }));
      }
    }
    phoneRef.current.value = "";
  };

  if (state.currentRoom.to === "" && state.userList?.length > 0) {
    socket.emit("getChatList", { user: state.userList[0] });
    setState((preState) => ({
      ...preState,
      currentRoom: {
        to: state.userList[0],
      },
      to: "",
    }));
  }

  const uploadFile = (files) => {
    console.log(files[0]);
    var file = files[0];

    socket.emit("sendFileMessage", {
      file: file,
      to: state.currentRoom.to,
      type: file.type,
      name: file.name,
    });
  };

  console.log(state);

  return (
    <div
      className="card m-0 card-chat rounded-0 overflow-hidden"
      style={{
        height: "95vh", padding: "5px", overflow: "hidden"
      }}
    >
      <h3 className="ms-2 mt-2">Chats</h3>
      <div className="card-body mt-2 d-flex p-0 h-100">
        <div className="chat-sidebar">
          <div className="d-flex mb-3 chat-contacts-search zc-invoice-list-controller-search px-2">
            {/* <form className="ms-2"> */}
            <input className="form-control" type="search" placeholder="Search" aria-label="Search" />
            <svg
              className="svg-inline--fa fa-search fa-w-16 contacts-search-icon"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="search"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              data-fa-i2svg=""
            >
              <path
                fill="currentColor"
                d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
              />
            </svg>

          </div>

          <div className="contacts-list scrollbar-overlay os-host os-theme-dark os-host-overflow os-host-overflow-y os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition">
            <div className="os-resize-observer-host observed">
              <div
                className="os-resize-observer"
                style={{ left: 0, right: "auto" }}
              />
            </div>
            <div
              className="os-size-auto-observer observed"
              style={{ height: "calc(100% + 1px)", float: "left" }}
            >
              <div className="os-resize-observer" />
            </div>
            <div
              className="os-content-glue"
              style={{ margin: 0, width: 278, height: 76 }}
            />
            <div className="os-padding">
              <div
                className="os-viewport os-viewport-native-scrollbars-invisible"
                style={{ overflowY: "scroll" }}
              >
                <div
                  className="os-content"
                  style={{ padding: 0, height: "100%", width: "100%" }}
                >
                  <div
                    className="nav nav-tabs border-0 flex-column"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    {state.userList?.map((user, index) => (
                      <ChatUserListItem
                        active={user === state.currentRoom.to}
                        lastMessage={state.lastMessages?.[user]?.message}
                        phone={user}
                        key={index}
                        name={state.userListName.find(
                          (username) => username.phone === user
                        )?.name}
                        onUserClick={setCurrenRoom}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="os-scrollbar os-scrollbar-horizontal os-scrollbar-unusable os-scrollbar-auto-hidden">
              <div className="os-scrollbar-track os-scrollbar-track-off">
                <div
                  className="os-scrollbar-handle"
                  style={{ width: "100%", transform: "translate(0px, 0px)" }}
                />
              </div>
            </div>
            <div className="os-scrollbar os-scrollbar-vertical os-scrollbar-auto-hidden">
              <div className="os-scrollbar-track os-scrollbar-track-off">
                <div
                  className="os-scrollbar-handle"
                  style={{
                    height: "10.4195%",
                    transform: "translate(0px, 0px)",
                  }}
                />
              </div>
            </div>
            <div className="os-scrollbar-corner" />
          </div>

        </div>
        <div className="tab-content card-chat-content">
          <div
            className="tab-pane card-chat-pane active"
            id="chat-0"
            role="tabpanel"
            aria-labelledby="chat-link-0"
          >
            <div className="chat-content-header">
              <div className="row flex-between-center">
                <div className="col-6 col-sm-8 d-flex align-items-center">
                  <a
                    className="pe-3 text-700 d-md-none contacts-list-show"
                    href="#!"
                  >
                    <svg
                      className="svg-inline--fa fa-chevron-left fa-w-10"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="chevron-left"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
                      />
                    </svg>
                  </a>
                  <div className="min-w-0 d-flex">
                    <div className="avatar avatar-xl status-online ">
                      <img
                        className="rounded-circle"
                        src="https://zeconcile1.s3.ap-southeast-1.amazonaws.com/1680244814157-Male_Memojis.png"
                        alt=""
                      />
                    </div>
                    <div className="ms-2">
                      <h5 className="mb-0 text-truncate fs-0">
                        {" "}
                        {state.currentRoom.to === ""
                          ? "Search the sender"
                          : (state.currentRoom.name) ? state.currentRoom.name : "+" + state.currentRoom.to}
                      </h5>
                      <div className="fs--2 text-400">Online</div>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <button
                    className="btn btn-sm  me-2"
                    type="button"
                    data-index={0}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title=""
                    data-bs-original-title="Start a Call"
                    aria-label="Start a Call"
                  >
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 22.125C17.5229 22.125 22 17.6479 22 12.125C22 6.60215 17.5229 2.125 12 2.125C6.47715 2.125 2 6.60215 2 12.125C2 17.6479 6.47715 22.125 12 22.125Z" stroke="#5945B3" strokeWidth="2" strokeLinejoin="round" />
                      <path d="M15.5 15.625C15.5 15.625 14.5 17.625 12 17.625C9.5 17.625 8.5 15.625 8.5 15.625" stroke="#5945B3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M15.5 9.125V11.125" stroke="#5945B3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M8.5 9.125V11.125" stroke="#5945B3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </button>

                  <button
                    className="btn btn-sm me-2"
                    type="button"
                    data-index={0}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title=""
                    data-bs-original-title="Start a Video Call"
                    aria-label="Start a Video Call"
                  >
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 22.125C17.5229 22.125 22 17.6479 22 12.125C22 6.60215 17.5229 2.125 12 2.125C6.47715 2.125 2 6.60215 2 12.125C2 17.6479 6.47715 22.125 12 22.125Z" stroke="#5945B3" strokeWidth="2" strokeLinejoin="round" />
                      <path d="M15.5 15.625C15.5 15.625 14.5 17.625 12 17.625C9.5 17.625 8.5 15.625 8.5 15.625" stroke="#5945B3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M15.5 9.125V11.125" stroke="#5945B3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M8.5 9.125V11.125" stroke="#5945B3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>


                    {/* <span className="fas fa-video"></span> Font Awesome fontawesome.com */}
                  </button>
                  <button
                    className="btn btn-sm "
                    type="button"
                    data-index={0}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title=""
                    data-bs-original-title="Conversation Information"
                    aria-label="Conversation Information"
                  >
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 22.125C17.5229 22.125 22 17.6479 22 12.125C22 6.60215 17.5229 2.125 12 2.125C6.47715 2.125 2 6.60215 2 12.125C2 17.6479 6.47715 22.125 12 22.125Z" stroke="#5945B3" strokeWidth="2" strokeLinejoin="round" />
                      <path d="M15.5 15.625C15.5 15.625 14.5 17.625 12 17.625C9.5 17.625 8.5 15.625 8.5 15.625" stroke="#5945B3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M15.5 9.125V11.125" stroke="#5945B3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M8.5 9.125V11.125" stroke="#5945B3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>

                  </button>
                </div>
              </div>
            </div>
            <div className="chat-content-body bg-chat-body" style={{ display: "inherit" }}>
              <div className="conversation-info" data-index={0}>
                <div className="h-100 overflow-auto scrollbar">
                  <div className="d-flex position-relative align-items-center p-3 border-bottom">
                    <div className="avatar avatar-xl status-online">
                      <img
                        className="rounded-circle"
                        src="https://zeconcile1.s3.ap-southeast-1.amazonaws.com/1673270451774-user.png"
                        alt=""
                      />
                    </div>
                    <div className="flex-1 ms-2 d-flex flex-between-center">
                      <h6 className="mb-0">
                        <a
                          className="text-decoration-none stretched-link text-700"
                          href="../pages/user/profile.html"
                        >
                          Antonys Hopkins
                        </a>
                      </h6>
                      <div className="dropdown z-index-1">
                        <button
                          className="btn btn-link btn-sm text-400 dropdown-toggle dropdown-caret-none me-n3"
                          type="button"
                          id="profile-dropdown-0"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <svg
                            className="svg-inline--fa fa-cog fa-w-16"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="cog"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            data-fa-i2svg=""
                          >
                            <path
                              fill="currentColor"
                              d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
                            />
                          </svg>
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-end border py-2"
                          aria-labelledby="profile-dropdown-0"
                        >
                          <a className="dropdown-item" href="#!">
                            Mute
                          </a>
                          <div className="dropdown-divider" />
                          <a className="dropdown-item" href="#!">
                            Archive
                          </a>
                          <a className="dropdown-item text-danger" href="#!">
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-3 pt-2">
                    <div className="nav flex-column font-sans-serif fw-medium">
                      <a
                        className="nav-link d-flex align-items-center py-1 px-0 text-600"
                        href="#!"
                      >
                        <span className="conversation-info-icon">
                          <svg
                            className="svg-inline--fa fa-search fa-w-16 me-1"
                            data-fa-transform="shrink-1 down-3"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="search"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            data-fa-i2svg=""
                            style={{ transformOrigin: "0.5em 0.6875em" }}
                          >
                            <g transform="translate(256 256)">
                              <g transform="translate(0, 96)  scale(0.9375, 0.9375)  rotate(0 0 0)">
                                <path
                                  fill="currentColor"
                                  d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                  transform="translate(-256 -256)"
                                />
                              </g>
                            </g>
                          </svg>
                        </span>
                        Search in Conversation
                      </a>
                      <a
                        className="nav-link d-flex align-items-center py-1 px-0 text-600"
                        href="#!"
                      >
                        <span className="conversation-info-icon">
                          <svg
                            className="svg-inline--fa fa-pencil-alt fa-w-16 me-1"
                            data-fa-transform="shrink-1"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="pencil-alt"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            data-fa-i2svg=""
                            style={{ transformOrigin: "0.5em 0.5em" }}
                          >
                            <g transform="translate(256 256)">
                              <g transform="translate(0, 0)  scale(0.9375, 0.9375)  rotate(0 0 0)">
                                <path
                                  fill="currentColor"
                                  d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"
                                  transform="translate(-256 -256)"
                                />
                              </g>
                            </g>
                          </svg>
                        </span>
                        Edit Nicknames
                      </a>
                      <a
                        className="nav-link d-flex align-items-center py-1 px-0 text-600"
                        href="#!"
                      >
                        <span className="conversation-info-icon">
                          <svg
                            className="svg-inline--fa fa-palette fa-w-16 me-1"
                            data-fa-transform="shrink-1"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="palette"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            data-fa-i2svg=""
                            style={{ transformOrigin: "0.5em 0.5em" }}
                          >
                            <g transform="translate(256 256)">
                              <g transform="translate(0, 0)  scale(0.9375, 0.9375)  rotate(0 0 0)">
                                <path
                                  fill="currentColor"
                                  d="M204.3 5C104.9 24.4 24.8 104.3 5.2 203.4c-37 187 131.7 326.4 258.8 306.7 41.2-6.4 61.4-54.6 42.5-91.7-23.1-45.4 9.9-98.4 60.9-98.4h79.7c35.8 0 64.8-29.6 64.9-65.3C511.5 97.1 368.1-26.9 204.3 5zM96 320c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm32-128c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128-64c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128 64c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z"
                                  transform="translate(-256 -256)"
                                />
                              </g>
                            </g>
                          </svg>
                        </span>
                        <span>Change Color</span>
                      </a>
                      <a
                        className="nav-link d-flex align-items-center py-1 px-0 text-600"
                        href="#!"
                      >
                        <span className="conversation-info-icon">
                          <svg
                            className="svg-inline--fa fa-thumbs-up fa-w-16 me-1"
                            data-fa-transform="shrink-1"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="thumbs-up"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            data-fa-i2svg=""
                            style={{ transformOrigin: "0.5em 0.5em" }}
                          >
                            <g transform="translate(256 256)">
                              <g transform="translate(0, 0)  scale(0.9375, 0.9375)  rotate(0 0 0)">
                                <path
                                  fill="currentColor"
                                  d="M104 224H24c-13.255 0-24 10.745-24 24v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24V248c0-13.255-10.745-24-24-24zM64 472c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zM384 81.452c0 42.416-25.97 66.208-33.277 94.548h101.723c33.397 0 59.397 27.746 59.553 58.098.084 17.938-7.546 37.249-19.439 49.197l-.11.11c9.836 23.337 8.237 56.037-9.308 79.469 8.681 25.895-.069 57.704-16.382 74.757 4.298 17.598 2.244 32.575-6.148 44.632C440.202 511.587 389.616 512 346.839 512l-2.845-.001c-48.287-.017-87.806-17.598-119.56-31.725-15.957-7.099-36.821-15.887-52.651-16.178-6.54-.12-11.783-5.457-11.783-11.998v-213.77c0-3.2 1.282-6.271 3.558-8.521 39.614-39.144 56.648-80.587 89.117-113.111 14.804-14.832 20.188-37.236 25.393-58.902C282.515 39.293 291.817 0 312 0c24 0 72 8 72 81.452z"
                                  transform="translate(-256 -256)"
                                />
                              </g>
                            </g>
                          </svg>
                          {/* <span className="fas fa-thumbs-up me-1" data-fa-transform="shrink-1"></span> Font Awesome fontawesome.com */}
                        </span>
                        Change Emoji
                      </a>
                      <a
                        className="nav-link d-flex align-items-center py-1 px-0 text-600"
                        href="#!"
                      >
                        <span className="conversation-info-icon">
                          <svg
                            className="svg-inline--fa fa-bell fa-w-14 me-1"
                            data-fa-transform="shrink-1"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="bell"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            data-fa-i2svg=""
                            style={{ transformOrigin: "0.4375em 0.5em" }}
                          >
                            <g transform="translate(224 256)">
                              <g transform="translate(0, 0)  scale(0.9375, 0.9375)  rotate(0 0 0)">
                                <path
                                  fill="currentColor"
                                  d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"
                                  transform="translate(-224 -256)"
                                />
                              </g>
                            </g>
                          </svg>
                        </span>
                        Notifications
                      </a>
                    </div>
                  </div>
                  <hr className="my-2" />
                  <div className="px-3" id="others-info-0">
                    <div className="title" id="shared-media-title-0">
                      <a
                        className="btn btn-link btn-accordion hover-text-decoration-none dropdown-indicator"
                        href="#shared-media-0"
                        data-bs-toggle="collapse"
                        aria-expanded="false"
                        aria-controls="shared-media-0"
                      >
                        Shared media
                      </a>
                    </div>
                    <div
                      className="collapse"
                      id="shared-media-0"
                      aria-labelledby="shared-media-title-0"
                      data-parent="#others-info-0"
                    >
                      <div className="row mx-n1">
                        <div className="col-6 col-md-4 px-1">
                          <a
                            href="../assets/img/chat/1.jpg"
                            data-gallery="images-1"
                          >
                            <img
                              className="img-fluid rounded-1 mb-2"
                              src="../assets/img/chat/1.jpg"
                              alt=""
                            />
                          </a>
                        </div>
                        <div className="col-6 col-md-4 px-1">
                          <a
                            href="../assets/img/chat/2.jpg"
                            data-gallery="images-1"
                          >
                            <img
                              className="img-fluid rounded-1 mb-2"
                              src="../assets/img/chat/2.jpg"
                              alt=""
                            />
                          </a>
                        </div>
                        <div className="col-6 col-md-4 px-1">
                          <a
                            href="../assets/img/chat/3.jpg"
                            data-gallery="images-1"
                          >
                            <img
                              className="img-fluid rounded-1 mb-2"
                              src="../assets/img/chat/3.jpg"
                              alt=""
                            />
                          </a>
                        </div>
                        <div className="col-6 col-md-4 px-1">
                          <a
                            href="../assets/img/chat/4.jpg"
                            data-gallery="images-1"
                          >
                            <img
                              className="img-fluid rounded-1 mb-2"
                              src="../assets/img/chat/4.jpg"
                              alt=""
                            />
                          </a>
                        </div>
                        <div className="col-6 col-md-4 px-1">
                          <a
                            href="../assets/img/chat/5.jpg"
                            data-gallery="images-1"
                          >
                            <img
                              className="img-fluid rounded-1 mb-2"
                              src="../assets/img/chat/5.jpg"
                              alt=""
                            />
                          </a>
                        </div>
                        <div className="col-6 col-md-4 px-1">
                          <a
                            href="../assets/img/chat/6.jpg"
                            data-gallery="images-1"
                          >
                            <img
                              className="img-fluid rounded-1 mb-2"
                              src="../assets/img/chat/6.jpg"
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="chat-content-scroll-area scrollbar">
                {state.chatList.map((chat) => (
                  <ChatListItem
                    deleteMessage={onDeleteMessage}
                    key={chat._id}
                    chat={chat}
                    alignRight={
                      chat.from === localStorage.getItem('ZC_org_WA_no_id')
                    }
                  />
                ))}
                <div ref={messagesEndRef} />
              </div>
            </div>
          </div>
          <form className="chat-editor-area" onSubmit={sendMessage}>


            <div className="d-flex align-items-center my-2">
              <input
                className="d-none"
                type="file"
                id="chat-file-upload"
                onChange={(e) => uploadFile(e.target.files)}
              />
              <label
                className="mb-0 p-1  cursor-pointer"
                htmlFor="chat-file-upload"
              >
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 4.125V20.125M20 12.125L4 12.125" stroke="#5945B3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </label>

              <input
                required
                className="form-control form-control-sm chat-contacts-search border-0 bg-chat-body px-3 h-100"
                type="text"
                placeholder="Type your message"
                ref={messageRef}
              />
              <button className="btn btn-sm " type="submit">
                <svg width="22" height="23" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.6644 12.1245L4.33594 19.5049L7.17455 12.1245L4.33594 4.74414L19.6644 12.1245Z" stroke="#5945B3" strokeWidth="2" strokeLinejoin="round" />
                  <path d="M18.2451 12.1245L7.05453 12.1247" stroke="#5945B3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div >
  );
}
export default ChatPage;
