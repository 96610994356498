const Alert = ({ children, status, setDisplayAlert }) => {
  return (
    <div
      className={`alert ${status ? "alert-success" : "alert-warning"} text-capitalize border-2 d-flex align-items-center p-1 mx-4`}
      role="alert"
    >
      <div className={`${status ? "bg-success" : "bg-warning"} me-3 icon-item`}>
        <span className="fas fa-check-circle text-white fs-3"></span>
      </div>
      <p className="mb-0 flex-1">{children}</p>
      <button
        className="btn-close"
        type="button"
        onClick={() => setDisplayAlert({ display: false })}
      ></button>
    </div>
  );
};

export default Alert;
