import Companies from "../pages/companies/companies";
import Invoice from "../pages/invoice";
import InvoiceDetail from "../pages/data/invoice-detail";
import SalesInvoices from "../pages/data/sales-invoices";
import Company from "../pages/company";
import invoiceList from "../pages/invoice-list";

import HomeComponent from "../pages/home";
import Login from "../pages/login/login";
import ChatPage from "../pages/ChatPage/ChatPage";
import AlldebtCred from "../pages/debt-cred"
import singleInvoice from "../pages/data/single-invoice";

const authProtectedRoutes = [
  { path: "/invoices", component: Invoice },
  { path: "/invoice/:id", component: InvoiceDetail },
  { path: "/invoice1/:id", component: singleInvoice },
  { path: "/sales-invoices", component: SalesInvoices },
  { path: "/companies", component: Companies },
  { path: "/company/:id", component: Company },
  { path: "/home", component: HomeComponent },
  { path: "/chat", component: ChatPage },
  { path: "/debt-cred/:id", component: AlldebtCred },
  { path: "/company1/:id", component: invoiceList }

];

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/", component: Login },
];

export { publicRoutes, authProtectedRoutes };
