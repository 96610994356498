import React from 'react';

const TextInput = ({ label, id, type, name, value, onChange }) => {
  return (
    <div key={'-'+label}>
      <label className="form-label" htmlFor={id}>
        {label}
      </label>
      <input
        className="form-control"
        id={id}
        type={type}
        name={name}
        onChange={(e) => onChange(e)}
        value={value}
      />
    </div>
  );
};

export default TextInput;
