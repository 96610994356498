import React from "react";

import Sidebar from "../common/sidebar";
// import TopBar from "../common/top-bar";

const AuthLayout = (props) => {
  return (
    <React.Fragment>
      <main className="main" id="top">
        <div className="container-fluid" data-layout="container">
          <Sidebar />
          <div className="content max-h-screen zc-main-content scrollbar" style={{ minHeight: "100vh" }}>
            {/* <TopBar /> */}
            {props.children}
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default AuthLayout;
