import React from "react";
import navigationHook from "../common/hooks";
import axios from 'axios';
// import { Redirect } from "react-router-dom";


class Home extends React.Component {

  logoutHandler = () => {
    localStorage.removeItem("token");
    window.location.reload(false);
  };

  constructor(props) {
    super(props);
    this.state = {
      baseUrl: process.env.REACT_APP_BASE_URL,
      selectedFile: null,
      phoneNumber: ''
    };
  }

  handlePhoneNumberChange = (event) => {
    // Remove all non-numeric characters from the input
    const newPhoneNumber = event.target.value.replace(/\D/g, '');

    // Update the state with the new phone number
    this.setState({
      phoneNumber: newPhoneNumber
    });
  };

  handleFileInputChange = (event) => {
    this.setState({
      selectedFile: event.target.files[0]
    });
  };

  handleFileUpload = () => {
    // TODO Show/Hide loader. 
    console.log("URL " + this.state.baseUrl);
    const formData = new FormData();
    formData.append('files', this.state.selectedFile);

    axios.post(`${this.state.baseUrl}/file/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(response => {
      console.log(response.data);
      let fileKey = response.data.data[0].location;
      console.log(fileKey);
      if (fileKey !== "") {
        this.processDoc({ key: fileKey, location: response.data.data[0].location, mobile: this.state.phoneNumber });
      }
    }).catch(error => {
      console.error(error);
    });
  }

  processDoc = (reqData) => {
    axios.post(`${this.state.baseUrl}/azureInvoice`, reqData, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }).then(response => {
      console.log(response.data.invoice_id);
      let url = response.data.invoice_id;
      window.location.href = `#/invoice/${url}`; // Put the id of invoice here. 
    }).catch(error => {
      console.error(error);
    });
  }

  render() {
    return (
      <>
        <div className="row g-3 m-0 mb-3">
          <div className="col mt-1">
            <div className="card bg-100 shadow-none border py-3">
              <div className="row gx-0 flex-between-center">
                <div className="col-sm-auto d-flex align-items-center">
                  <img
                    className="ms-n2"
                    src="../assets/img/illustrations/crm-bar-chart.png"
                    alt=""
                    width="90"
                  />
                  <div>
                    <h6 className="text-primary fs--1 mb-0">Welcome to </h6>
                    <h4 className="text-primary fw-bold mb-0">
                      Ze<span className="text-info fw-medium">Concile</span>
                    </h4>
                    {/* <button
                    className="dropdown-item"
                    onClick={this.logoutHandler}
                  >
                    Logout
                  </button> */}
                  </div>
                  <img
                    className="ms-n4 d-md-none d-lg-block"
                    src="../assets/img/illustrations/crm-line-chart.png"
                    alt=""
                    width="150"
                  />
                </div>
                <div className="col-md-auto p-3">

                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Quick testing feature. */}
        <div className="quick-testing-main">
          <div className="quick-testing">
            <h2 className="text-center"> Quick testing form </h2>
            <div className="quick-test-form">
              <div className="formItem">
                <label className="quickform-label"> Mobile Number </label>
                <input className="form-control" type="tel" maxLength="10"
                 onChange={this.handlePhoneNumberChange}
                 value={this.state.phoneNumber} placeholder="Enter your 10 digit mobile number" />
              </div>
              <div className="formItem">
                <label className="quickform-label"> Invoice File </label>
                <input className="form-control" type="file" onChange={this.handleFileInputChange} />
              </div>
              <div className="formItem">
                <button onClick={this.handleFileUpload} className="zeconcile-btn border mt-3 mx-auto d-block"> Process my Invoice file</button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default navigationHook(Home);
