import React from "react";
import navigationHook from "../common/hooks";
import { NavLink } from "react-router-dom";
import axios from "axios";

class Invoices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      baseUrl:  process.env.REACT_APP_BASE_URL ,//"http://localhost:8100/api", //"https://zeconcile.thesecondwheel.in/api",,
      invoices: [],
      page: 1,
      query: [],
      gst: false,
      totalPage: 1,
    };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log("GOT ERROR");
    console.log(error);
    return { hasError: true };
  }

  // componentDidCatch(error, errorInfo) {
  //     // You can also log the error to an error reporting service
  //     console.log(error);
  //     console.log(errorInfo);
  // }
  componentDidMount() {
    try {
      this.getInvoices();
    } catch (e) {
      console.log(e);
    }
  }
  // componentDidCatch(error, errorInfo) {
  //     // You can also log the error to an error reporting service
  //     console.log(error, errorInfo);
  // }

  getInvoices = async () => {
    try {
      console.log("called me");
      let response = await axios.get(
        `${this.state.baseUrl}/invoice/` + this.state.page,
        { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
      );
      let data = response.data.data;
      console.log(data);
      console.log(response.data);
      // // localStorage.setItem('fileKey', data.files[0].key);
      // this.state = {
      // }
      this.setState({
        invoices: data,
        totalPage: response.data.totalPage,
      });
      // this.getParseData();
    } catch (e) {
      console.log(e);
    }
  };

  keyChange = async (e) => {
    let index = e.target.attributes.getNamedItem("data-key").value;
    console.log(index);
    localStorage.setItem("fileKey", index);
    this.getParseData();
  };

  pageChange = async (e) => {
    let p = e.target.attributes.getNamedItem("data-page").value;
    console.log(p);
    await this.setState({
      page: Number(p),
    });
    this.getInvoices();
  };

  render() {
    let menuItems = [];
    let pre = Number(this.state.page) - 1;
    let next = Number(this.state.page) + 1;
    menuItems.push(
      <li className={`page-item ${pre <= 0 ? "disabled" : ""}`}>
        <span
          className="page-link"
          data-page={pre}
          onClick={pre <= 0 ? null : this.pageChange}
        >
          Previous
        </span>
      </li>
    );
    for (var i = 0; i < this.state.totalPage; i++) {
      menuItems.push(
        <li
          className={`page-item ${this.state.page === i + 1 ? "active" : ""}`}
        >
          <span
            className="page-link"
            onClick={this.pageChange}
            data-page={i + 1}
          >
            {i + 1}
          </span>
        </li>
      );
    }
    menuItems.push(
      <li
        className={`page-item ${next > this.state.totalPage ? "disabled" : ""}`}
      >
        <span className="page-link" onClick={this.pageChange} data-page={next}>
          Next
        </span>
      </li>
    );
    return (
      <>
        <div className="row g-3 m-0 mb-3">
          <div className="col mt-1">
            <div className="card bg-100 shadow-none border py-3">
              <div className="row gx-0 flex-between-center">
                <div className="col-sm-auto d-flex align-items-center">
                  <img
                    className="ms-n2"
                    src=""
                    alt=""
                    width="90"
                  />
                  <div>
                    <h6 className="text-primary fs--1 mb-0">Invoices </h6>
                    {/* <h4 className="text-primary fw-bold mb-0">Ze<span className="text-info fw-medium">Concile</span></h4> */}
                  </div>
                  {/* <img className="ms-n4 d-md-none d-lg-block" src="../assets/img/illustrations/crm-line-chart.png" alt="" width="150" /> */}
                </div>
                <div className="col-md-auto p-3">
                  <form className="row align-items-center g-3">
                    <div className="col-auto">
                      {/* <h6 className="text-700 mb-0">Showing Data For: </h6> */}
                    </div>
                    <div className="col-md-auto position-relative">
                      {/* <input className="form-control form-control-sm datetimepicker ps-4 flatpickr-input" id="CRMDateRange" type="text" data-options="{&quot;mode&quot;:&quot;range&quot;,&quot;dateFormat&quot;:&quot;M d&quot;,&quot;disableMobile&quot;:true , &quot;defaultDate&quot;: [&quot;Apr 21&quot;, &quot;Apr 28&quot;] }" readonly="readonly" /> <span className="fas fa-calendar-alt text-primary position-absolute top-50 translate-middle-y ms-2"> </span> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-3 m-0 mb-3 card bg-100 shadow-none border py-3 d-none">
          <div className="table-responsive scrollbar">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Invoice Number</th>
                  <th scope="col">Narration</th>
                  <th scope="col">Sub Total</th>
                  <th scope="col">Tax</th>
                  <th scope="col">Grand Total</th>
                  <th scope="col">Status</th>
                  {/* <th scope="col">Invoice Numbe</th> */}
                  {/* <th className="text-end" scope="col">Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {this.state.invoices.map((item, index) => (
                  <tr key={index}>
                    <td>{item.inv_date}</td>
                    <td>
                      <NavLink to={"/invoice/" + item._id}>
                        {item.inv_no}
                      </NavLink>
                    </td>
                    <td>{item.client_company}</td>
                    <td>{item.subtotal}</td>
                    <td>{item.tax ? item.tax : "-"}</td>
                    <td>{item.grand_total}</td>
                    <td>
                      {item.status === 0
                        ? "Pending"
                        : " " || item.status === 1
                        ? "Approved"
                        : "" || item.status === 2
                        ? "Rejected"
                        : ""}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="7" className="p-0 pt-3">
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-center">
                        {menuItems}
                        {/* <li className="page-item"><a className="page-link" href="#">Previous</a></li>
                                                            <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                            <li className="page-item"><a className="page-link" href="#">Next</a></li> */}
                      </ul>
                    </nav>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div className="row g-3 m-0 mb-3 card bg-100 shadow-none border py-3">
        <div className="table-responsive scrollbar text-center ">
        Select client to view data
        </div>
        </div>

        <div className="row g-3 m-0 mb-3 card bg-100 shadow-none border py-3 d-none">
          <div className="table-responsive scrollbar">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Query</th>
                  <th scope="col">Ans.</th>
                  {/* <th className="text-end" scope="col">Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {this.state.query.map((item, index) => (
                  <tr key={index}>
                    <td>{item.query}</td>
                    <td>{item.answer ? item.answer[0].text : ""}</td>
                  </tr>
                ))}
                {this.state.gst ? (
                  <tr key="-2">
                    <td>GST</td>
                    <td>{this.state.gst}</td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default navigationHook(Invoices);
