import React from "react";
import { HashRouter, Switch } from "react-router-dom";
import axios from "axios";

import AuthLayout from "./layout/auth_layout";
import NonAuthLayout from "./layout/non_auth_layout";

import { publicRoutes, authProtectedRoutes } from "./routes";

import "./App.css";
import Authmiddleware from "./routes/route";

export const NotificationContext = React.createContext(null);

axios.interceptors.request.use(
  function (request) {
    const token = localStorage.getItem("token");
    if (token) {
      request.headers.common.Authorization = `Bearer ${token}`;
    }
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Switch>
          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={AuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
            />
          ))}

          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}
        </Switch>
      </HashRouter>
    </div>
  );
}

export default App;
